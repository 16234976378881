import PropTypes from "prop-types";
import React, { useState } from "react";
import styled from "styled-components";

//import { ReactComponent as AddFile } from '../../../../Assets/icon-editor/add-file.svg';
import { ReactComponent as AddIcon } from '../../../../Assets/icon-editor/item-add-normal.svg'
import { ReactComponent as AddFocusIcon } from '../../../../Assets/icon-editor/item-add-focus.svg'
import { IconButton } from "@mui/material";
import { ReactComponent as CheckIcon } from '../../../../Assets/Icons/Check.svg'

interface Props {
  Icon: any;
  name: string;
  onCommand: any;
  src?:string;
  selected?:boolean
  disabled?:boolean
}

const StyledIconListItem = styled.div`
  &.list {
    margin-bottom: 8px;
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

  & .img {
    background-color: var(--color-background-primary, #FFF);
    border: 1px solid;
    border-color: var(--tokens-color-border-secondary-duplicate);
    border-radius: var(--tokens-radius-01-duplicate);
    height: 72px;
    overflow: hidden;
    position: relative;
    width: 128px;

    &:hover {
      background-color: var(--tokens-color-background-secondary-duplicate);
      border: 1px solid;
      border-color: var(--tokens-color-border-active-duplicate);
      border-radius: var(--tokens-radius-01-duplicate);
      height: 72px;
      overflow: hidden;
      position: relative;
      width: 128px;
    }

    &.selected {
      border: 2px solid;
      border-color: #3617CE;
    }
  }

  & .add-icon-btn {
    height: 16px !important;
    left: 8px !important;
    position: absolute !important;
    top: 8px !important;
    width: 16px !important;
    cursor: pointer;
  }

  & .text-wrapper-3 {
    color: var(--tokens-color-text-primary-duplicate);
    font-family: var(--pre-caption-01-font-family);
    font-size: var(--pre-caption-01-font-size, 12px);
    font-style: var(--pre-caption-01-font-style);
    font-weight: var(--pre-caption-01-font-weight, 600);
    letter-spacing: var(--pre-caption-01-letter-spacing);
    line-height: var(--pre-caption-01-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .btn {
    align-items: center;
    border-radius: var(--tokens-radius-02-duplicate);
    display: flex;
    flex-direction: column;
    gap: var(--tokens-spacing-02-duplicate);
    height: 91px;
    justify-content: center;
    overflow: hidden;
    padding: var(--tokens-spacing-04-duplicate);
    position: relative;
    width: 128px;
    cursor: pointer;
  }

  & .list-btn-icon-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
    & .text-wrapper-4 {
      color: var(--tokens-color-text-brand2-duplicate, #17191C);
      font-family: var(--pre-caption-04-font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 800;
      letter-spacing: var(--pre-caption-01-letter-spacing);
      // line-height: 170%;
      letter-spacing: -0.14px;
      text-align: center;
    }
  }

  & .list-btn-icon {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  &.disabled{
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const IconListItem = ({ Icon, name, src=undefined, onCommand, selected=false, disabled=false }: Props): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnterVideo = (e)=>{
    if(src){
      e.target.setAttribute("loop", true)
      if(e.target.paused){
        e.target.play()
      }
    }
  }
  const handleMouseLeaveVideo = (e)=>{
    e.target.removeAttribute("loop")    
  }

  return (
    <StyledIconListItem className={disabled?"list disabled":"list"} 
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={()=>{onCommand("add")}}
    >
      {Icon !== null && (
        <>
          <div className={"img"}>
            {isHovered ?
              <AddFocusIcon className="add-icon-btn" width={16} height={16} onClick={()=>onCommand("add")}/>
              :
              <AddIcon className="add-icon-btn" width={16} height={16} onClick={()=>onCommand("add")}/>
            }
            <div className="list-btn-icon-wrapper">
              <Icon />
            </div>
          </div>
          <div className="frame-6">
            <div className="text-wrapper-3">{ name }</div>
          </div>
        </>
      )}

      {Icon === null && (
        <>
          <div className={`img${selected?" selected":""}`} style={{display:"flex",justifyContent:"center"}}>
            {/* {isHovered ?
              <AddFocusIcon className="add-icon-btn" width={16} height={16} onClick={()=>onCommand("add")}/>
              :
              <AddIcon className="add-icon-btn" width={16} height={16} onClick={()=>onCommand("add")}/>
            } */}
            {
              src && 
              <video className={`icon-list-video`} onContextMenu={(e)=>{e.preventDefault()}} style={{position:"absolute"}} width={"100%"} src={src} autoPlay muted onMouseEnter={handleMouseEnterVideo} onMouseLeave={handleMouseLeaveVideo}/>
            }
            <div className="list-btn-icon-wrapper">
              <div className="text-wrapper-4">{ name }</div>
            </div>
          </div>
          <div className="frame-6" style={{display:"flex", gap:"4px"}}>
            {selected&&<CheckIcon style={{stroke:"#3617CE"}}/>}
            <div className="text-wrapper-3">{ name }</div>
          </div>
      </>
      )}
    </StyledIconListItem>
  );
};

IconListItem.propTypes = {
  property1: PropTypes.oneOf(["add", "file"]),
  state: PropTypes.oneOf(["hover-active-selected", "default"]),
};