import React, { useState } from "react";
import { Box, IconButton, InputBase, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { styled } from '@mui/material/styles';

interface Props {
  min?: number;
  max?: number;
  value: number;
  text: string;
  width: number;
  unitText? : string;
  onChange: any;
  step?:number
}

const StyledTypography = styled(Typography)(({ theme }) => ({
  width: 'fit-content',
  color: theme.palette.secondary.main,
}));


export const LabeledInputStepper = ({
  min = null,
  max = null,
  value = 0,
  width = 140,
  text = "",
  unitText = "",
  onChange,
  step=1
}: Props): JSX.Element => {

  const handleIncrease = () => {
    //setMyValue(prevValue => prevValue + 1);
    const newVal = parseFloat(value.toString()) + parseFloat(step.toString());
    console.log(`${newVal} = ${value} + ${step}`)
    if ( max !== null && newVal > max) return;
    onChange(newVal);
  };

  const handleDecrease = () => {
    //setMyValue(prevValue => (prevValue > 0 ? prevValue - 1 : 0)); // 0 이하로는 감소하지 않도록
    const newVal = parseFloat(value.toString()) - parseFloat(step.toString())
    if ( min !== null && newVal < min) return;
    onChange(newVal);
  };

  const handleChange = (event: { target: { value: any; }; }) => {
    const newValue = Number(event.target.value);
    if (!isNaN(newValue)) {
      if ( (min !== null && newValue < min) || (max !== null && newValue > max) ) {
        return;
      } 
     // setMyValue(newValue);
      onChange(newValue);
    }
  };
  
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0}
      pr={0}
      pl={1}
      py={0}
      pt={0}
      pb={0}
      bgcolor="#ffffff"
      borderRadius={1}
      border={1}
      borderColor="divider"
      height={32}
      width={width}
    >
      <StyledTypography variant="body2" >{text}</StyledTypography>
      <InputBase value={value} onChange={handleChange} sx={{ mr: 0.5, flex: 1}} inputProps={{ style: {textAlign:'right'} }} />
      <Typography variant="body2" sx={{ width: 'fit-content', color: 'black' }}>{unitText}</Typography>
      {/* <input
        type="number"
        value={value}
        onChange={handleChange}
        style={{ border: 'none', outline: 'none', flex: 1 }} // 기본 스타일 제거
      /> */}
      <Box display="flex" flexDirection="column" alignItems="center" gap={0} pl={0} pr={0.5}>
        <IconButton size="small" sx={{width: 16, height: 15}} onClick={handleIncrease} >
          <ArrowDropUpIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" sx={{width: 16, height: 15}} onClick={handleDecrease} >
          <ArrowDropDownIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

LabeledInputStepper.propTypes = {
  value: PropTypes.number,
  text: PropTypes.string,
  width: PropTypes.number,
  onChange: PropTypes.any
};
