import { createContext } from "react";

interface FilterObject{
  name:string,
  intensity:number
}

interface EditingPanelContextInterface{
  // filter: FilterObject,
  isLoading:boolean,

  // setFilter:(newFilter)=>void,
  setIsLoading:(newValue)=>void
}

const EditingPanelContextDefault:EditingPanelContextInterface = {
  // filter:undefined,
  isLoading:false,

  // setFilter:(newFilter)=>{},
  setIsLoading:(newFilter)=>{}
}

export const EditingPanelContext = createContext(EditingPanelContextDefault);