import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper, Stack } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import { LabeledInputStepper } from "../../../../../../../Components/pages/Editor/LabeledInputStepper";
import { IconListItem } from "../../../../../../../Components/pages/Editor/IconListItem";

import { ColorPicker } from '../../../../../../../Components/pages/Editor/ColorPicker';

import textEffectData from '../../../../../../../Data/textEffect.json'
import fontData from '../../../../../../../Data/fontData.json'
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../../../../editorPageContext";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";
import { useTranslation } from "react-i18next";


import AIButton from '../../../../../../../Components/common/Button'
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";
import { TextPanelContext } from "../../../TextPanelContext";
import { PanelContext } from "../../../../PanelContext";


const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: flex-start;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
  height: 100%;
  // background-color:green;
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledFilterBox = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  border: 1px solid;
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-radius: var(--tokens-radius-none-duplicate);
  height: 72px;
  position: relative;
  width: 128px;
`
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  width:"100%",
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));


export const EffectTabContent = ({index}): JSX.Element => {
  const { t } = useTranslation();
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [blur, setBlur] = useState(0);
  const [degree, setDegree] = useState(0);
  const [rotation, setRotation] = useState('left');
  const [opacity, setOpacity] = useState(0);
  const [color, setColor] = useState("#ffffff")

  const [sceneIndex, setSceneIndex]=useState(0);

  const {textList, setTextList, shadowList, setShadowList} = useContext(TextPanelContext)

  const shopInfoContext = useContext(ShopInfoContext)
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext);  

  const {textIndex, setTextIndex} = useContext(PanelContext)

  useEffect(()=>{
    setSceneIndex(editorPageContext.selectIndex)
  },[editorPageContext.selectIndex])

  const handleChangeX = (event: Event, newValue: number | number[]) => {
    setSizeX(newValue as number);
  };

  const handleChangeY = (event: Event, newValue: number | number[]) => {
    setSizeY(newValue as number);
  };

  // const handleChangeOpacity = (event: Event, newValue: number | number[]) => {
  //   setOpacity(newValue as number);
  // };

  const handleChangeColor = (value, index) => {
    let tempShadowList = JSON.parse(JSON.stringify(shadowList));
    tempShadowList[index].color = value;

    setShadowList(tempShadowList);
    // setColor(value);
    // editorPageContext.selectedItem.clip.setContentValue("color", value);
  }

  function matchTextEffect(target){
    // console.log("target :: ",target)
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      if(shopInfoContext.language==="ko-kr"){
        return textEffectData[index].name
      }else{
        return textEffectData[index].name+"_en"
      }
    }
  }
  function matchFont(target){
    // console.log("font target :: ", target);
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }

  const [prevUrl, setPrevUrl] = useState(undefined)
  const handleCommand = async (name:string) => {
    // console.log(`Text Effect: ${name}`);
  };

  const [effects, setEffects]=useState(undefined);
  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      const effects = scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex].textList.map(text=>text.effect)
      setEffects(effects);
    }
  },[editorPageContext.selectIndex, scenarioContext.finalScenario])

  useEffect(()=>{
    setSceneIndex(editorPageContext.selectIndex)
    if(editorPageContext.selectIndex!=undefined){
      const shadows = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
      console.log("Shadow List :: ", editorContext.sceneTextShadowEffectList);
  
      setPositionX(shadows.map(shadow=>shadow.posX));
      setPositionY(shadows.map(shadow=>shadow.posY));
      setBlur(shadows.map(shadow=>shadow.blur));
      setColor(shadows.map(shadow=>shadow.color));
      setOpacity(shadows.map(shadow=>shadow.opacity*100));
    }
  },[editorPageContext.selectIndex])

  const handleChangePosX = (val:any, index:number)=>{
    let tempShadowList = JSON.parse(JSON.stringify(shadowList));
    tempShadowList[index].posX = val;
    setShadowList(tempShadowList)
  }
  const handleChangePosY = (val:any, index:number)=>{
    let tempShadowList = JSON.parse(JSON.stringify(shadowList));
    tempShadowList[index].posY = val;
    setShadowList(tempShadowList)
  }
  const handleChangeOpacity = (val:any, index:number)=>{
    let tempShadowList = JSON.parse(JSON.stringify(shadowList));
    tempShadowList[index].opacity = (parseFloat(val)/100).toFixed(2);
    setShadowList(tempShadowList);
  }
  const handleChangeBlur = (val:any, index:number)=>{
    let tempShadowList = JSON.parse(JSON.stringify(shadowList));
    tempShadowList[index].blur = val.toFixed(1)
    setShadowList(tempShadowList);
  }

  useEffect(()=>{
    console.log("shadow data changed ::: ", shadowList)
  },[shadowList])

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "출렁이는 효과":
        enName="Wave"
        break;
      case "인트로 효과":
        enName="Intro"
        break;
      case "날아오는 효과":
        enName="Fly"
        break;
      case "타자치는 효과":
        enName="Typing"
        break;
      case "반투명 효과":
        enName="Transparent"
        break;
      case "깜박이는 효과":
        enName="Blink"
        break;
      case "없음":
        enName="None"
        break;
    }
    return enName;
  }

  const handleClickTextItem = (index) => {
    setTextIndex(index);
  }

  return (
    <StyledFrame>
      {textList?.map((text, index)=>{
        return(

        <Accordion key={`text-effect-property-${index}`} expanded={index===textIndex} onClick={()=>{handleClickTextItem(index)}}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            sx={{ width:"100%", padding:0, minHeight:21, height: 21, '& .MuiAccordionSummary-content':{width:"90%"} }}
          >
            <Typography variant="caption" color={index===textIndex?"#3617CE":"rgba(68,75,82,1)"} sx={{ flex: 1 }}>{`# ${text.text}`}</Typography>
          </AccordionSummary>
          <AccordionDetails >
            <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
                sx={{ padding:0, minHeight:21, height: 21 }}
              >
                <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>{t("pages-editor-main-textpanel.c1")}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
                <Stack gap={1} direction="row" sx={{ width: 'inherit', overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  {
                    text && 
                    <IconListItem Icon={null} src={text.effect==="없음"?undefined:`./text_thumb_${matchTextEffect(text.effect)}.mp4`} name={translate(text.effect)} onCommand={handleCommand}/>  
                  }
                  {/* <IconListItem Icon={null} name="출렁이는" onCommand={handleCommand}/>   */}
                </Stack>
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
              <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
                sx={{ padding:0, minHeight:21, height: 21 }}
              >
                <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>{t("pages-editor-main-textpanel.c2")}</Typography>
              </AccordionSummary>
              <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
                <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                  <LabeledInputStepper value={shadowList[index].posX} text="X" width={140} onChange={(val:number)=>handleChangePosX(val, index)}/>
                  <LabeledInputStepper value={shadowList[index].posY} text="Y" width={140} onChange={(val:number)=>handleChangePosY(val, index)}/>
                </Box>
                <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                  <LabeledInputStepper step={0.1} value={shadowList[index].blur} text={t("pages-editor-main-textpanel.c3")} min={0} max={100} width={140} onChange={(val:number)=>handleChangeBlur(val, index)}/>
                  {/* <LabeledInputStepper value={positionY} text="퍼짐효과" width={140} onChange={(val:number)=>setPositionY(val)}/> */}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                  <ColorPicker value={shadowList[index].color} onChange={(value)=>handleChangeColor(value, index)}/>
                  <LabeledInputStepper value={Math.round(shadowList[index].opacity*100)} text={t("pages-editor-main-textpanel.c4")} min={0} max={100} width={140} unitText="%" onChange={(val:number)=>handleChangeOpacity(val, index)}/>
                </Box>    
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>
        )

      })}
      
    </StyledFrame>
  );
};

