import React, {useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react"
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import tutorialData from '../../../Data/tutorialData.json'

import Modal from "../Modal";
import Button from "../Button";
import CheckBox from "../CheckBox";

import styled, { css } from "styled-components";

import { useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

function TutorialModal(props){
  const { t } = useTranslation();
  const [disableChecked, setDisableChecked] = useState(false);

  const handleChangeCheck = (checked:boolean)=>{
    setDisableChecked(checked);
  }

  const handleClickClose = () => {
    if(disableChecked){
      localStorage.setItem("ENABLE_TUTO", `${false}`);
    }
    if(props.onClose){
      props.onClose()
    }
  }

  return(
    <Modal {...props}>
      <ModalContentStyle>
        <SwiperStyle
          spaceBetween={10}
          slidesPerView={1}
          pagination={true}
          modules={[Pagination]}
          loop={false}            
          style={{width:'100%'}}
          >
          {tutorialData.map((tutorialInfo, index) => {

            return(
              <SwiperSlideStyle key={index} style={{width:"100%"}}>
                <SlideVideoWrapper>
                  <video width="100%" height="100%" muted autoPlay loop onContextMenu={(e)=>{e.preventDefault()}}>
                    <source src={tutorialInfo.video} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>
                </SlideVideoWrapper>
                <SlideTextWrapper>
                  {tutorialInfo.text.split('\n').map((text, index)=>{
                    return(
                      <span>{text}</span>
                    )
                  })}
                </SlideTextWrapper>
              </SwiperSlideStyle>
            )
          }
          )}
        </SwiperStyle>
        <ModalFootStyle>
          <CheckBoxWrapper >
            <CheckBox
              value={disableChecked}
              onChange={handleChangeCheck}
            />{t("com-common-tutorialmodal.m1")}
          </CheckBoxWrapper>
          <Button
            $buttonType="capsule"
            $variant="brand1"
            text={t("com-common-tutorialmodal.m2")}
            showRightIcon
            rightIcon="CaretRight"
            onClick={handleClickClose}
          />
        </ModalFootStyle>
      </ModalContentStyle>
    </Modal>
  )
}

export default TutorialModal

const ModalContentStyle = styled.div`
  width:520px;
  display:flex;
  flex-direction:column;

  gap:${(props)=>props.theme.spacing["spacing-08"]};
  padding-top:${(props)=>props.theme.spacing["spacing-11"]};
  padding-right:${(props)=>props.theme.spacing["spacing-13"]};
  padding-bottom:${(props)=>props.theme.spacing["spacing-11"]};
  padding-left:${(props)=>props.theme.spacing["spacing-13"]};

  user-select:none;
`


const SwiperSlideStyle = styled(SwiperSlide)`
  width:100%;
  height:100%;
  display:flex;
  flex-direction: column;
  gap:${(props)=>props.theme.spacing["spacing-08"]};
`

const SlideVideoWrapper = styled.div`
  user-select:none;
  border-radius:${(props)=>props.theme.radius["radius-04"]};
  overflow:hidden;
  width: 520px;
  height: 280px;
`

const SlideTextWrapper = styled.div`
  ${(props)=>props.theme.typography["pre-body-01"]};
  display:flex;
  flex-direction:column;
  justify-content:center;
  text-align: center;
  cursor:default;
`

const ModalFootStyle = styled.div`
  width:100%;
  display:flex;
  justify-content: space-between;
  align-items: flex-end;

  user-select:none;
`

const SwiperStyle = styled(Swiper)`

  position:relative;
  width:100%;
  height:100%;
  display:flex;
  flex-direction:column;

  gap:0px;

  & .swiper-wrapper{
    position:relative;
    display:flex;
  }

  & .swiper-pagination{
    display:flex;
    position:relative;
    justify-content:center;
    align-items:flex-end;
    margin-top:${(props)=>props.theme.spacing["spacing-07"]};
    
    gap:0;
    height: 8px;
    transform:translate(0,0);
  }

  & .swiper-pagination-bullet{
    width: 8px;
    height: 8px;
    opacity: 1;
    border-radius: 50%;    
    background-color: ${(props)=>props.theme.colors["icon-disabled"]}
  }

  & .swiper-pagination-bullet-active {
    background-color: ${(props)=>props.theme.colors["icon-brand1"]}
  }
`

const CheckBoxWrapper = styled.div`
  display:flex;
  flex-direction:row;
  ${(props)=>props.theme.typography["pre-body-06"]};
  color:${(props)=>props.theme.colors["text-secondary"]};
  gap:${(props)=>props.theme.spacing["spacing-04"]}
`