import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { TabContainer, ImageTabContainer, TransitionTabContainer, TextEffectTabContainer, BackgroundMusicTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import { WebGetUrl } from "../../../../Api/resource";
import { EditorPageContext } from "../../editorPageContext";

import { useTranslation } from "react-i18next";
import i18n from "../../../../locales/i18n";

const StyledEffectPanel = styled.div`
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-right-style: solid;
  border-right-width: 1px;
  position: relative;
  width: 432px;
  display: flex;
  flex-direction: column;
  align-items:center;
  height: 100%;
`;

export const EffectPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectNum, setSelectNum] = useState(0);
  const [imageUrlList, setImageUrlList] = useState([]);
  const [bgmUrlList, setBgmUrlList] = useState([]);

  const shopInfoContext = useContext(ShopInfoContext)
  const scenarioContext = useContext(ScenarioContext)
  const editorPageContext = useContext(EditorPageContext);

  const handleSelect = (num: number) => {
    if(!editorPageContext.selectIndex){
      editorPageContext.setSelectIndex(0);
    }
    switch(num){
      case 0:
      case 1:
      case 2:
        editorPageContext.setSelectType("video");
        break;
      case 3:
        editorPageContext.setSelectType("text");
        break;
      case 4:
        editorPageContext.setSelectType("bgm");
        break;
    }
    setSelectNum(num);

  }

  useEffect(()=>{
    switch(editorPageContext.selectType){
      case "video":{
        if(selectNum>2){
          setSelectNum(0);
        }
        break;
      }
      case "text":{
        if(selectNum!==3){
          setSelectNum(3);          
        }
        break;
      }
      case "bgm":{
        if(selectNum!==4){
          setSelectNum(4);
        }
        break;
      }
    }
  },[editorPageContext.selectType])


  useEffect(()=>{
    let newList=[];
    shopInfoContext.editedFiles.map(fileInfo=>{
      const url = URL.createObjectURL(fileInfo.file)
      const name = fileInfo.file.name;
      const type = fileInfo.mainCategory==="image"? t("pages-editor-main-effectpanel.m1") : t("pages-editor-main-effectpanel.m2");
      newList.push({url:url, type:type, name:name})
    })
    setImageUrlList(newList)

  },[shopInfoContext.editedFiles])

  useEffect(()=>{
    getBgmUrl()
  },[scenarioContext.BGMInfo])

  async function getBgmUrl(){
    try{
      const urlList=[]    
      for(let i=0; i<scenarioContext.BGMInfo.length; i++){
        const urlRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: scenarioContext.BGMInfo[i].path
        }
        const url = await WebGetUrl(urlRequest);
        urlList.push({
          url: url.presignedUrl,
          title: scenarioContext.BGMInfo[i].musicTitle,
          duration: `00:${scenarioContext.BGMInfo[i].duration}`
        })
        if(i==0){
          editorPageContext.setSelectedBgmInfo({
            path: scenarioContext.BGMInfo[i].path,
            blobUrl: "",
            presignedUrl: url.presignedUrl,
            title: scenarioContext.BGMInfo[i].musicTitle,
            duration: `00:${scenarioContext.BGMInfo[i].duration}`,
          })
        }
      }
      setBgmUrlList(urlList);
    }catch(err){
      console.error("get BGM URL failed");
    }
  }

  return (
    <StyledEffectPanel>
      <TabHeader selectNum={selectNum} onClick={handleSelect}/>
      {selectNum==0 &&<TabContainer urlList={imageUrlList}/>}
      {selectNum==1 &&<ImageTabContainer />}
      {selectNum==2 &&<TransitionTabContainer />}
      {selectNum==3 &&<TextEffectTabContainer />}
      {selectNum==4 &&<BackgroundMusicTabContainer bgmList={bgmUrlList} />}
    </StyledEffectPanel>
  );
};
