import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Icon7 } from "../../icons/Icon7";
import { useNavigate } from "react-router-dom";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { useTranslation } from "react-i18next";
import { EditorPageContext } from "../../../../Pages/Editor/editorPageContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";

import { ReactComponent as AlertIcon } from "../../../../Assets/ModalIllu/Close.svg";
import AlertModal from "../../../common/AlertModal";


const StyledHeader = styled.div`
  background-color: var(--tokens-color-background-primary-duplicate);
  position:relative;
  display: flex;
  // width: 1440px;
  min-height: 64px;
  height: 64px;
  // padding: 14px 16px;
  justify-content: center;
  box-sizing:border-box;
  align-items: center;
  // gap: 801px;

  & .frame-14 {
    align-items: center;
    display: inline-flex;
    gap: var(--tokens-spacing-04-duplicate);
    left: 16px;
    position: absolute;

    // top: 18px;
  }

  & .p {
    color: var(--color-text-primary, #17191C);
pre/body/03 
font-family: "Pretendard Variable";
font-size: 16px;
font-style: normal;
font-weight: 600;
// line-height: 170%; /* 27.2px */
letter-spacing: -0.16px;
  }

  & .frame-15 {
    align-items: center;
    background-color: var(--tokens-color-badge-brand2-duplicate);
    border-radius: var(--tokens-radius-full-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    height:100%;
    justify-content: center;
    padding: 0px var(--tokens-spacing-04-duplicate) 0px var(--tokens-spacing-04-duplicate);
    position: relative;
  }

  & .text-wrapper-9 {
    color: var(--tokens-color-text-primary-inverse-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    
    margin-top: 0px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .frame-16 {
    align-items: center;
    display: inline-flex;
    
    gap: 8px;
    right: 16px;
    position: absolute;
    // top: 14px;
  }

  & .button-capsule {
    align-items: center;
    background-color: var(--tokens-color-button-brand1-subtle-duplicate);
    border: 1.5px solid var(--color-border-brand, #3617CE);
    // border-color: var(--tokens-color-border-brand-duplicate);
    border-radius: var(--tokens-radius-full-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-02-duplicate);
    height: 36px;
    justify-content: center;
    padding: 3px var(--tokens-spacing-07-duplicate) 0px var(--tokens-spacing-07-duplicate);
    position: relative;
    cursor: pointer;
  }

  & .icon-7 {
    height: 16px !important;
    position: relative !important;
    width: 16px !important;
    margin-bottom: 0px;
  }

  & .button {
    all: unset;
    box-sizing: border-box;
    color: var(--tokens-color-text-brand1-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .button-wrapper {
    align-items: center;
    background-color: var(--tokens-color-button-brand1-duplicate);
    border-radius: var(--tokens-radius-full-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-02-duplicate);
    height: 36px;
    justify-content: center;
    padding: 3px var(--tokens-spacing-07-duplicate) 0px var(--tokens-spacing-07-duplicate);
    position: relative;
    cursor: pointer;
  }

  & .button-2 {
    all: unset;
    box-sizing: border-box;
    color: var(--tokens-color-text-primary-inverse-duplicate);
    font-family: var(--pre-body-05-font-family);
    font-size: var(--pre-body-05-font-size);
    font-style: var(--pre-body-05-font-style);
    font-weight: var(--pre-body-05-font-weight);
    letter-spacing: var(--pre-body-05-letter-spacing);
    line-height: var(--pre-body-05-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
`;


export const Header = (props): JSX.Element => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const shopInfoContext = useContext(ShopInfoContext)
  const editorContext = useContext(EditorContext);
  const scenarioContext = useContext(ScenarioContext);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(undefined);
  const [alertIcon, setAlertIcon] = useState(<AlertIcon/>)

  const {backupBGMVolume, backupTTSVolume, backupShadows, backupScreenEffect} = useContext(EditorPageContext)
  
  const handleOnClickReturn=()=>{
    setAlertMessage(<>
      <span>편집을 취소하시겠습니까?</span><br/>
      <span>이 페이지를 벗어나면 편집된 내용은 저장되지 않습니다.</span>
    </>);
    setShowAlert(true);
  }

  const handleConfirmReturn = () => {
    if(props.backupData !== undefined){
      scenarioContext.setFinalScenario(JSON.parse(JSON.stringify(props.backupData)))
      editorContext.setBgmVolume(backupBGMVolume)
      editorContext.setTtsVolumeList(backupTTSVolume)
      editorContext.setSceneTextShadowEffectList(backupShadows)
      editorContext.setScreenEffectList(backupScreenEffect)
    }

    setShowAlert(false);

    switch(shopInfoContext.completedStep){
      case 0:
        props.setStep(1)
        nav("/")
        break;
      case 1:
        props.setStep(shopInfoContext.completedStep+1)
        nav("/point")
        break;
      case 2:
        props.setStep(shopInfoContext.completedStep+1)
        nav("/scenario")
        break;
      case 3:
        props.setStep(shopInfoContext.completedStep+1)
        nav("/edit")
        break;
      case 4:
        props.setStep(4)
        nav("/edit")
        break;
    }
  }

  const handleExportVideo = () => {
    editorContext.setVideoCreated(false);
    editorContext.setIsEdited(true);
    editorContext.setAutoExport(true)
    props.setStep(4)
    nav("/edit")
  }


  return (
    <StyledHeader>
      <div className="frame-14">
        <p className="p">[{shopInfoContext.shopName}] {t("com-pages-editor.c1")}</p>
        <div className="frame-15">
          <div className="text-wrapper-9">{t("com-pages-editor.c2")}</div>
        </div>
      </div>
      <div className={`frame-16`}>
        <div className="button-capsule tutorials tutorial-step-6 tutorial-focus-6"  onClick={handleOnClickReturn}>
          <Icon7 className="icon-7" color="#3617CE" />
          <button className="button">{t("com-pages-editor.c3")}</button>
        </div>
        <div className="button-wrapper" onClick={handleExportVideo}>
          <button className="button-2">{t("com-pages-editor.c4")}</button>
        </div>
      </div>
      {
        showAlert&&
        <AlertModal
          icon={alertIcon}
          description={alertMessage}
          show={showAlert}
          onConfirm={handleConfirmReturn}
          onCancel={()=>{setShowAlert(false)}}
          style={{zIndex:20000}}
        />
      }

    </StyledHeader>
  );
};