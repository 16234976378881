import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"

import ProgressBar from "../common/ProgressBar"
import Button from "../common/Button"

import styled, {createGlobalStyle} from "styled-components"
import theme from "../../styles/theme"

import AlertModal from "../common/AlertModal"
import { ReactComponent as CloseIcon } from "../../Assets/ModalIllu/Close.svg"


import { AsideStyle, ProgressStyle, AsideFrameStyle, AsideTextFrameStyle, MainTextStyle, SubTextStyle, NameTextStyle} from './Aside.styled'
import { ShopInfoContext } from "../../Contexts/ShopInfoContext"

import { Trans, useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

import { MenuItem, Select, Box } from "@mui/material"

import {ReactComponent as GlobeIcon} from "../../Assets/Icons/Globe.svg"
import {ReactComponent as ArrowDown} from "../../Assets/Icons/CaretDown.svg"
import { url } from "inspector"


const CALLBACK_URL = `https://${process.env.REACT_APP_BTV_TOWN_MY_ADV_URL}`

const GlobalStyle = createGlobalStyle`
  .MuiPaper-root{  
    border-radius: ${theme.radius['radius-02']} !important;
    background-color: ${theme.colors["background-primary"]} !important;
    transform : translate(0px , ${theme.spacing["spacing-02"]}) !important;

    .select-language li{
      color: ${theme.colors["text-primary"]};
      paddingRight: ${theme.spacing["spacing-08"]};
      paddingLeft: ${theme.spacing["spacing-08"]};
      ${theme.typography["pre-body-02"]};
    };
    .select-language li:hover{
      background:${theme.colors["background-primary-hover"]};
    }
    .select-language li.Mui-selected{
      background:${theme.colors["background-secondary"]};
    }
    .select-language li.Mui-selected:hover{
      background:${theme.colors["background-primary-hover"]};
    }

    .MuiList-root{      
      border-radius: ${theme.radius['radius-02']};
    }
  }
`


function Aside(props){
  const [exitAlert, setExitAlert] = React.useState<boolean>(false);

  const location = useLocation();
  const { t } = useTranslation();

  useEffect(()=>{
    switch(props.step){
      case 1:
        nav('/')
        break;
      case 2:
        nav('/point')
        break;
      case 3:
        nav('/scenario')
        break;
      case 4:
        nav("/edit")
        break;
    }
  },[props.step])

  const onClickExitButton = (e:React.MouseEvent) =>{
    // console.log("return to my ad...")
    setExitAlert(true);
  }
  const closeExitAlert = (e:any) => {
    // console.log("close exit alert")
    setExitAlert(false)
  }
  const alertIcon = <CloseIcon/>
  const alertText=<>
    <Trans
      i18nKey="aside.alert"
      components={{
        span:<span/>,
        br:<br/>
      }}
    />
  </>

  const handleOK = () => {
    sessionStorage.clear();
    if(process.env.REACT_APP_SERVER_TYPE==="DEV"){
      window.parent.location.replace(window.location.host)
    }else{
      window.parent.location.replace(CALLBACK_URL)
    }
  }

  const nav = useNavigate();
  const handleStep = (stepNumber)=>{
    if(stepNumber===5){
      props.setStep(stepNumber)
      nav('/editor');
    }

    if(stepNumber <= shopInfoContext.completedStep+1){
      console.log("step to ", stepNumber)
      props.setStep(stepNumber)
      switch(stepNumber){
        case 1:
            nav('/');
          break;
        case 2:
            nav('/point');
          break;
        case 3:
            nav('/scenario');
          break;
        case 4:
            nav('/edit');
          break;
      }
    }
  }

  const [mainText, setMainText] = useState(<></>);
  const [subText, setSubText] = useState(<></>);

  const [userName,setUserName]=useState(sessionStorage.getItem("USER_NAME"));
  const shopInfoContext = useContext(ShopInfoContext);
  const {shopName, isGenerating} = shopInfoContext

  const {language, setLanguage} = shopInfoContext
  

  const [slicedShopName, setSlicedShopName] = useState(undefined);
  useEffect(()=>{
    if(shopName.length < 15){
      setSlicedShopName(shopName)
    }else{
      const sliced = shopName.slice(0,15) + "...";
      setSlicedShopName(sliced);
    }
  },[shopName])

  useEffect(()=>{
    setUserName(props.name);
  },[props.name])

  useEffect(()=>{
    if(!userName){
      const nameOnStorage = sessionStorage.getItem("USER_NAME")
      if(nameOnStorage){
        setUserName(nameOnStorage);
      }
    }
  },[userName])
  
  useEffect(()=>{
    switch(props.step){
      case 1:
        setMainText(
          <TextWrapper>
            <Trans i18nKey="aside.m1"
              components={{ NameTextStyle: <NameTextStyle /> }}
              values={{userName}}
            />
          </TextWrapper>
        )
        setSubText(
          <TextWrapper>
           <Trans i18nKey="aside.m2" />
          </TextWrapper>
        )
        break;
      case 2:
        setMainText(
          <TextWrapper>
            <Trans i18nKey="aside.m3"
              components={{ NameTextStyle: <NameTextStyle /> }}
              values={{shopName}}
            />
          </TextWrapper>
        )
        setSubText(
          <TextWrapper>
            <Trans i18nKey="aside.m4"
              values={{userName}}
            />
          </TextWrapper>
        )

        break;
      case 3:
        setMainText(
          <TextWrapper>
            <Trans i18nKey="aside.m5"
              components={{ NameTextStyle: <NameTextStyle /> }}
              values={{shopName}}
            />
          </TextWrapper>
        )
        setSubText(
          <TextWrapper>
            <Trans i18nKey="aside.m6" />
          </TextWrapper>
        )
        break;
      case 4:
        if(isGenerating){
          setMainText(
            <TextWrapper>
              <Trans i18nKey="aside.m7" />
            </TextWrapper>
          );
          setSubText(
            <TextWrapper>
              <Trans i18nKey="aside.m8"
                values={{userName}}
              />
            </TextWrapper>
          );
        }else{
          setMainText(
            <TextWrapper>
              <Trans i18nKey="aside.m9"
                components={{ NameTextStyle: <NameTextStyle /> }}
                values={{shopName, userName}}
              />
            </TextWrapper>
          );
          setSubText(
            <TextWrapper>
              <Trans i18nKey="aside.m10" />
            </TextWrapper>
          );
        }
        break;
    }
  },[props.step, userName, isGenerating, language])

  const [move,setMove] = useState(false);

  const handleChangeLang = (e) => {

    console.log("change lang :: ", e.target.value);
    localStorage.setItem("LANG", e.target.value)
    setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  }

  const options = [
    {value:"ko-kr", label:"KOR"},
    {value:"en-us", label:"ENG"}
  ]

  const muiSelectStyle={
    'width' : '104px',
    'backgroundColor' : theme.colors["background-secondary-hover"],
    'borderRadius' : theme.radius["radius-full"],
    'display': 'flex',
    'alignItems': 'center',
    'gap': theme.spacing["spacing-04"],
    'height': '36px',
    'position' : 'relative',
  
    'border':'none !important',
    'outline': 'none !important',
    'paddingRight':`0px !important`,
  
    // 'color': theme.colors["text-brand2"],
    'stroke': theme.colors["text-brand2"],
    
    'cursor' : 'pointer', 

    '.MuiOutlinedInput-notchedOutline':{
      border: 'none !important'
    },

    '& .MuiPaper-root':{
      backgroundColor:"red"
    }
  }

  return (
    <AsideStyle style={{display:props.step===5?"none":"flex"}}>
      <GlobalStyle/>
      <AsideFrameStyle>
        <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
          <Button
            $buttonType="text-l"
            showLeftIcon={true}
            leftIcon="House"
            text={t(`aside.return-ads`)}
            onClick={onClickExitButton}
          />
          {
            (process.env.REACT_APP_SERVER_TYPE==="DEV") && 
            <Select
              className={"select-language"}
              value={language}
              onChange={handleChangeLang}
              IconComponent={ArrowStyle}
              renderValue={(selected)=>{
                const selectedItem = options.find((option) => option.value === selected);
                return (
                  <Box sx={{ display: "flex", alignItems: "center", gap: "4px", border:"none" }}>
                    <div style={{width:"20px", height:"20px"}}><GlobeIcon style={{width:"20px", height:"20px"}}/></div>
                    <SelectTextWrapper>
                      {selectedItem.label}
                    </SelectTextWrapper>                    
                  </Box>
                )
              }}
              sx={muiSelectStyle}
            >
              <MenuItem value="ko-kr">KOR</MenuItem>
              <MenuItem value="en-us">ENG</MenuItem>
              {/* <option value="ko-kr">KOR</option>
              <option value="en-us">ENG</option> */}
            </Select>
          }
        </div>
        <AsideTextFrameStyle>
          <MainTextStyle className={move?"move":undefined}>{mainText}</MainTextStyle>
          <SubTextStyle>{subText}</SubTextStyle>
        </AsideTextFrameStyle>
      </AsideFrameStyle>
      <ProgressStyle>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(1)}} number={1} text={t("aside.menu1")} status={getStatus(1,props.step)}/>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(2)}} number={2} text={t("aside.menu2")} status={getStatus(2,props.step)}/>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(3)}} number={3} text={t("aside.menu3")} status={getStatus(3,props.step)}/>
        <ProgressBar disabled={isGenerating} onClick={()=>{handleStep(4)}} number={4} text={t("aside.menu4")} status={getStatus(4,props.step)}/>
      </ProgressStyle>
      <AlertModal
        icon={alertIcon}
        description={alertText}
        show={exitAlert}
        onCancel={closeExitAlert}
        onConfirm={handleOK}
      />      
    </AsideStyle>
  )

  function getStatus(num, step){
    if(num>step){
      return "todo"
    }else if(num===step){
      return "ing"
    }else{
      return "done"
    }
  }
}

export default Aside

const TextWrapper = styled.div`
  word-break: break-word;
`

const ArrowStyle=styled(ArrowDown)<any>`
  width: 16px;
  height: 16px;
  stroke: ${(props)=>props.theme.colors["icon-brand-subtle"]};
  position: absolute !important;
  right:${(props)=>props.theme.spacing['spacing-05']} !important;
  
`

const SelectTextWrapper=styled.div`
  ${(props)=>props.theme.typography["pre-body-05"]};
  color:${(props)=>props.theme.colors['text-brand2']}
`