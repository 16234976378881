import { Box, Popover, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { SketchPicker } from 'react-color';

import styled from "styled-components";

function hexToHsl(hex: string): string {
  // Remove the leading '#' if present
  hex = hex.replace(/^#/, '');

  // Parse r, g, b values from the hex string
  let r = parseInt(hex.substring(0, 2), 16) / 255;
  let g = parseInt(hex.substring(2, 4), 16) / 255;
  let b = parseInt(hex.substring(4, 6), 16) / 255;

  // Find the minimum and maximum values to get lightness
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h = 0, s = 0, l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  // Convert h, s, l to percentage values
  return `[${('00'+Math.round(h * 360)).slice(-3)}, ${ ('00'+Math.round(s * 100)).slice(-3)}, ${('00'+Math.round(l * 100)).slice(-3)}]`;
}

interface Props {
  value: string;
  onChange: any;
}

export const ColorPicker = ({value, onChange} : Props): JSX.Element => {
  const [color, setColor] = useState(value);
  const [isShow, setIsShow] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


  useEffect(() => {
    setColor(value);
  }, [value]);

  const handleChangeComplete = color => {
    setColor(color.hex);
    // setIsShow(false);
    // setAnchorEl(null);
    onChange(color.hex);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsShow(true);
  };

  const handleClose = () => {
    setIsShow(false);
    setAnchorEl(null);
  };

  const pickerColors=[
    ["#000000","#3B3B3B","#5B5B5B","#8E8E8E","#AEAEAE","#C5C5C5","#D4D4D4","#EDEDED","#F1F1F1","#FFFFFF"],
    ["#8B000C","#FC0016","#FD8B20","#FFFD2F","#33FE29","#28FFFE","#3E7EE1","#0020FA","#881CFB","#F910FB"],
    ["#E1AEA6","#F1C4C6","#FBE0C8","#FFEFC7","#D5E7CE","#CADCDF","#C2D6F6","#C9DEF0","#D3CCE5","#E6CBD7"],
    ["#D67162","#E58D8F","#F7C394","#FFE092","#AFD1A0","#99BDC1","#99BCF0","#95BFE3","#B4A7D2","#CE9CB4"],
    ["#C33526","#D9585D","#F4A765","#FFD263","#8BBC75","#6C9CA5","#6196E5","#64A0D5","#8172B9","#B86F95"],
    ["#9A140E","#C30011","#E18339","#EFB839","#639E4A","#3E7682","#3270D0","#357CBC","#5A479B","#9A436D"],
    ["#781A13","#8C000D","#AA5217","#B7831B","#356A20","#164651","#004FC2","#0B4B87","#2C1D68","#67183E"],
    ["#500E08","#5A0008","#6C3610","#745411","#254416","#102E35","#183E7A","#0A3157","#1C1443","#42112A"],
  ]

  const handleClickColor=(color)=>{
    // printHsl();
    setColor(color);
    onChange(color);
  }
  
  function printHsl(){
    const hslPalette=[]

    pickerColors.map(line=>{
      const hslLine = line.map(color=>hexToHsl(color))
      hslPalette.push(hslLine)
    })

    console.log("hsl :: ", hslPalette);
  }

  return (
    <>
      <Box 
        display="flex"
        alignItems="center"
        height={32}
        gap={2} pr={1} pl={1} py={0.5}
        flex={1}
        borderRadius={1} border={1} borderColor="#E2E4E7"
        sx={{ cursor: "pointer"}}
        onClick={handleClick}
      >
        <Box width={16} height={16} bgcolor={color} borderRadius={0.5}></Box>
        <Typography flex={1} variant="body2" color="text.primary" fontWeight="normal"> { color } </Typography>
      </Box>
      <Popover
        open={isShow}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
          {/* <SketchPicker color={color} onChangeComplete={handleChangeComplete} /> */}
          <ColorPalette onClickItem={handleClickColor} palette={pickerColors} currentColor={color}/>
      </Popover>
    </>
  );
};


type ColorGroup = string[];
type ColorPaletteGroup = ColorGroup[];

interface ColorPaletteProp{
  palette: ColorPaletteGroup,
  onClickItem?:Function,
  currentColor?:string,
}

function ColorPalette({palette, onClickItem: onClick=undefined, currentColor=undefined}:ColorPaletteProp){

  const handleClickItem = (color)=>{
    if(onClick){
      onClick(color)
    }
  }

  return(
    <ColorPaletteStyle>
      {
        palette.map(colorGroup=>{
          return(
            <ColorLineStyle>
              {
                colorGroup.map(color=>{
                  return(
                    <ColorItemStyle onClick={()=>{handleClickItem(color)}} hex={color} currentColor={currentColor}/>
                  )
                })
              }
            </ColorLineStyle>
          )
        })
      }
    </ColorPaletteStyle>
  )
}

const ColorPaletteStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap:${props => props.theme.spacing["spacing-02"]};
  padding:${props => props.theme.spacing["spacing-06"]};
`

const ColorLineStyle = styled.div`
  display: flex;
  gap: ${props=>props.theme.spacing["spacing-02"]};
`

const ColorItemStyle = styled.div<{hex, currentColor}>`
  width: 16px;
  height: 16px;
  border-radius: ${props=>props.theme.radius["radius-01"]};
  background-color: ${(props:any)=>props.hex};
  cursor: pointer;

  border: ${(props)=>{
    if(props.currentColor === props.hex){
      return `2px solid ${props.theme.colors["border-active"]}`
    }
    if(props.hex === "#FFFFFF" || props.hex === "#F1F1F1" || props.hex === "#EDEDED"){
      return `1px solid ${props.theme.colors['border-primary']}`
    }
  }};
`