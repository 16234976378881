import React, { useContext, useEffect, useState } from "react";
import { Header } from "../../Components/pages/Editor/Header";
import { HeaderSub } from "../../Components/pages/Editor/HeaderSub";
import { Main } from "./Main";
import { ThemeProvider } from "../../theme/ThemeProvider";
import styled from "styled-components";
import fontDataJson from "../../Data/fontData.json"
import screenEffectJson from "../../Data/screenEffect.json"

import { WebGetFile, WebGetUrl, WebPostPreviewBlank, WebPostPreviewScene, WebPostPreviewText, WebSave } from '../../Api/resource';
// import { WebInitHibernation } from "../../Api/ai";
import { fileTypeFromBlob } from 'file-type';

import { EditorPageContext, SelectType } from "./editorPageContext";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../Contexts/ScenarioContext";

import textEffectData from "../../Data/textEffect.json"
import fontData from "../../Data/fontData.json"
import templateData from "../../Data/templateData.json"
import { EditorContext } from "../../Contexts/EditorContext";
import LoadingScreen from "../../Components/common/LoadingScreen";
import { ShopPointContext } from "../../Contexts/ShopPointContext";
import AlertModal from "../../Components/common/AlertModal";
import { ReactComponent as AlertIcon } from "../../Assets/ModalIllu/Close.svg";

import { useTranslation } from "react-i18next";
import { callHibernation, matchEffect } from "../../Utilities";

const StyledElementEditor = styled.div`
  background-color: #f6f7fd;
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  box-sizing:border-box;

  overflow-x:hidden;
  overflow-y:scroll;

  &::-webkit-scrollbar{  
    width:0px;
  }
`;

const StyledHeaderWrapper = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  height: 800px;
  position: relative;
  width: 1440px;
`;

const EditorWrapper = styled.div`
  display: flex;
  position:relative;
  flex-direction:column;
  width:100%;
  height:100%;
  min-width: 1600px;

  overflow:hidden;

`

const Editor = (props): JSX.Element => {

  const { t } = useTranslation();

  const [enableTutorial, setEnableTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);

  useEffect(()=>{
    callHibernation();
  },[])

  const [selectType, setSelectType] = useState<SelectType>(undefined)
  const [selectIndex, setSelectIndex] = useState<number|undefined>(undefined)
  const [selectedBgmInfo, setSelectedBgmInfo] = useState(undefined)
  const [bgmUrlList, setBgmUrlList] = useState([]);
  const [bgmBlobs, setBgmBlobs] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [ntrActorList, setNtrActorList] = useState([]);
  const [ntrUrlList, setNtrUrlList]=useState([])
  const [textVideoUrlList, setTextVideoUrlList] = useState([])
  const [sceneVideoUrlList, setSceneVideoUrlList] = useState([])
  const [blankVideoUrl, setBlankVideoUrl] = useState("")
  const [totalDuration, setTotalDuration] = useState(0);
  const [isInpainting, setIsInpainting] = useState(false);
  const [aiEraserList, setAiEraserList] = useState([]);
  const [aiBgList, setAiBgList] = useState([])
  const [textRectList, setTextRectList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [backupScenario, setBackupScenario]=useState(undefined)
  const [backupBGMVolume,setBackupBGMVolume]=useState(undefined)
  const [backupTTSVolume, setBackupTTSVolume]=useState(undefined)
  const [backupShadows,setBackupShadows]=useState(undefined)
  const [backupScreenEffect,setBackupScreenEffect]=useState(undefined)

  const [showAlert, setShowAlert] = useState(true);
  const [alertMessage, setAlertMessage] = useState(undefined)
  const [alertIcon, setAlertIcon] = useState(<AlertIcon/>)
  const [alertConfirmFunc, setAlertConfirmFunc] = useState(undefined);
  const [alertCancelFunc, setAlertCancelFunc] = useState(undefined);

  const editorPageContextValue = {
    selectType: selectType,
    selectIndex: selectIndex,
    selectedBgmInfo:selectedBgmInfo,
    bgmUrlList:bgmUrlList,
    bgmBlobs:bgmBlobs,
    selectedItem:selectedItem,
    ntrActorList:ntrActorList,
    ntrUrlList:ntrUrlList,
    textVideoUrlList:textVideoUrlList,
    sceneVideoUrlList:sceneVideoUrlList,
    blankVideoUrl:blankVideoUrl,
    totalDuration:totalDuration,
    isInpainting:isInpainting,
    aiEraserList:aiEraserList,
    aiBgList:aiBgList,
    textRectList:textRectList,
    isLoading: isLoading,
    backupScenario: backupScenario,
    backupBGMVolume:backupBGMVolume,
    backupTTSVolume:backupTTSVolume,
    backupShadows:backupShadows,
    backupScreenEffect:backupScreenEffect,
    setBackupBGMVolume:(newVal)=>{setBackupBGMVolume(newVal)},
    setBackupTTSVolume:(newVal)=>{setBackupTTSVolume(newVal)},
    setBackupShadows:(newVal)=>{setBackupShadows(newVal)},
    setBackupScreenEffect:(newVal)=>{setBackupScreenEffect(newVal)},

    showAlert:showAlert,
    alertMessage:alertMessage,
    alertConfirmFunc:alertConfirmFunc,
    alertCancelFunc:alertCancelFunc,

    setAlertConfirmFunc:(newFunc)=>{setAlertConfirmFunc(newFunc)},
    setAlertCancelFunc:(newFunc)=>{setAlertCancelFunc(newFunc)},

    setSelectType: (newType)=>{setSelectType(newType)},
    setSelectIndex: (newIndex)=>{setSelectIndex(newIndex)},
    setSelectedBgmInfo: (newInfo)=>{setSelectedBgmInfo(newInfo)},
    setBgmUrlList: (newList)=>{setBgmUrlList(newList)},
    setBgmBlobs: (newBlobs)=>{setBgmBlobs(newBlobs)},
    setSelectedItem:(newItem)=>{setSelectedItem(newItem)},
    setNtrActorList:(newList)=>{setNtrActorList(newList)},
    setNtrUrlList:(newList)=>{setNtrUrlList(newList)},
    setTextVideoUrlList:(newList)=>{setTextVideoUrlList(newList)},
    setSceneVideoUrlList:(newList)=>{setSceneVideoUrlList(newList)},
    setBlankVideoUrl:(newUrl)=>{setBlankVideoUrl(newUrl)},
    setTotalDuration:(newVal)=>{setTotalDuration(newVal)},
    setIsInpainting:(newVal)=>{setIsInpainting(newVal)},
    setAiEraserList:(newList)=>{setAiEraserList(newList)},
    setAiBgList:(newList)=>{setAiBgList(newList)},
    setTextRectList:(newList)=>{setTextRectList(newList)},
    setIsLoading:(newVal)=>{setIsLoading(newVal)},
    setBackupScenario:(newVal)=>{setBackupScenario(newVal)},
    setShowAlert: (newVal)=>{setShowAlert(newVal)},
    setAlertMessage: (newVal)=>{setAlertMessage(newVal)}
  }

  const scenarioContext = useContext(ScenarioContext)
  const editorContext = useContext(EditorContext);
  const shopInfoContext = useContext(ShopInfoContext)

  const [isLoadingData, setIsLoadingData] = useState([true,true,true,true,true,true]);
  const [isSavingData, setIsSavingData] = useState(false);

  const [contact, setContact] = useState(false);

  useEffect(()=>{
    if(scenarioContext.finalScenario !== undefined){
      setBackupScenario(JSON.parse(JSON.stringify(scenarioContext.finalScenario)))
    }
    setBackupBGMVolume(JSON.parse(JSON.stringify(editorContext.bgmVolume)));
    setBackupTTSVolume(JSON.parse(JSON.stringify(editorContext.ttsVolumeList)))
    setBackupShadows(JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList)))
    setBackupScreenEffect(JSON.parse(JSON.stringify(editorContext.screenEffectList)))    
  },[])

  function getTextMetric(text, fontName, size) {
    const fontIdx = fontData.findIndex(font=>font.fontName === fontName);
    const fontFamily = fontData[fontIdx]["font-family"];
    const fontConfig = `${size}px ${fontFamily}`
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = fontConfig;
    const metrics = context.measureText(text);
    // console.log(`${text} ${fontConfig}\n`,metrics)
    return metrics;
  }

  useEffect(()=>{
    if(scenarioContext.finalScenario){
      if(scenarioContext.finalScenario.scenes){
        let newDuration=0
        scenarioContext.finalScenario.scenes.map(scene=>{newDuration+=scene.time})
        if(newDuration!==totalDuration){
          setTotalDuration(newDuration);
          getBlankUrl();
        }

        const newList = [];
        scenarioContext.finalScenario.scenes.forEach((scene, sceneIndex)=>{
          const textList = scene.textList;
          const rotate = templateData.baseScene[scenarioContext.selectedTemplate].textPos[sceneIndex][0].rotate
          const rectList = textList.map(item=>{
            const metric = getTextMetric(item.text, item.font, item.height);
            return(
              {
                x:item.posX,
                y:item.posY,
                // h:metric.actualBoundingBoxAscent+metric.actualBoundingBoxDescent,
                h:item.height,
                w:metric.width,
                rotate:rotate
              }
            )
          })

          newList.push(rectList)
        })
        setTextRectList(newList);
      }
    }
  },[scenarioContext.finalScenario])

  useEffect(()=>{
    setContact(true)
    // WebInitHibernation({assetId:sessionStorage.getItem("ASSET_ID")});
  },[])

  useEffect(()=>{
    if(contact){
      getBgmUrl();
      getBgmBlobs();
      getNtrUrl();
      getTextVideo();
      getSceneVideo();
      loadBlankUrl();
      const eraserList=[];
      const bgList=[];
      scenarioContext.finalScenario.scenes?.forEach((scene, index)=>{
        eraserList.push([]);
        bgList.push([]);
      })
      setAiEraserList(eraserList);
      setAiBgList(bgList);
    }
  },[contact])

  async function getBlankUrl(){
    let duration=0;
    scenarioContext.finalScenario.scenes.map(scene=>{duration += scene.time})
    const request = {
      assetId:sessionStorage.getItem("ASSET_ID"),
      duration:duration
    }

    const blankVideoRepsonse:any = await WebPostPreviewBlank(request);

    if(blankVideoRepsonse.status===200){
      const blob = new Blob([blankVideoRepsonse.data],{type:"video/mp4"})
      const url = await URL.createObjectURL(blob);
      setBlankVideoUrl(url);
    }else{
      setBlankVideoUrl("");
    }
  }

  async function loadBlankUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[5]=true;
      return newState;
    })

    await getBlankUrl();

    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[5]=false;
      return newState;
    })
  }
  
  async function getBgmUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[0]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.BGMInfo.length; i++){
      const response = await WebGetUrl({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: scenarioContext.BGMInfo[i].path
      })
      if(response.result===0){
        newList.push(response.presignedUrl);
      }else{
        console.error(`failed get bgm url index_${i}`);
        newList.push("")
      }
    }
    setBgmUrlList(newList);
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[0]=false;
      return newState;
    })
  }
  async function getBgmBlobs(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[1]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.BGMInfo.length; i++){
      const response = await WebGetFile({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: scenarioContext.BGMInfo[i].path
      })
      if(!response.result){
        newList.push(response);
      }else{
        console.error(`failed get bgm blob index_${i}`);
        newList.push(undefined)
      }
    }
    setBgmBlobs(newList);
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[1]=false;
      return newState;
    })
  }

  async function getNtrUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[2]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.finalScenario.scenes.length; i++){
      // console.log("tts path ::",scenarioContext.finalScenario.scenes[i].narrationInfo.path)
      const path = scenarioContext.finalScenario.scenes[i].narrationInfo.path;
      // const fileName = `adv_api_server/${sessionStorage.getItem("ASSET_ID")}/tts/scene`+("00"+i.toString()).slice(-2)+".mp3"
      if(path && path!==""){
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: path
        })
        if(response.result===0){
          newList.push(response.presignedUrl);
        }else{
          console.error(`failed get bgm url index_${i}`);
          newList.push("")
        }
      }
    }
    
    setNtrUrlList(newList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[2]=false;
      return newState;
    })
  }

  async function getTextVideo(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[3]=true;
      return newState;
    })
    function matchTextEffect(target){
      // console.log("target :: ",target)
      const index = textEffectData.findIndex(item=>item.effect===target)
      if(index<0){
        return 'none'
      }else{
        return textEffectData[index].name
      }
    }
    function matchFont(target){
      // console.log("font target :: ", target);
      const index = fontData.findIndex(font=>font.fontName===target)
      if(index>=0){
        return fontData[index]["fontFile"]
      }else{
        return fontData[0]["fontFile"]
      }
    }

  
    const textUrlList = []
    for(let sceneIndex=0; sceneIndex<scenarioContext.finalScenario.scenes.length;sceneIndex++){
      const sceneData = scenarioContext.finalScenario.scenes[sceneIndex]
      const shadow = editorContext.sceneTextShadowEffectList[sceneIndex]
      textUrlList.push([]);
      for(let textIndex=0; textIndex<sceneData.textList.length; textIndex++){
        const request = {
          "assetId":sessionStorage.getItem("ASSET_ID"),
          "text": sceneData.textList[textIndex].text,
          "fontSize": sceneData.textList[textIndex].height,
          "textWidth":450,
          "fontFile": matchFont(sceneData.textList[textIndex].font),
          "fontColor": sceneData.textList[textIndex].fontColor,
          "hasShadow": sceneData.fileName==="search"?false:true, 
          "posX": sceneData.textList[textIndex].posX,
          "posY": sceneData.textList[textIndex].posY,
          "duration": sceneData.time,
          "rotate":sceneData.textList[textIndex].rotate||0,
          "textEffect":  sceneData.textList[textIndex].effect==="search"?"search":matchTextEffect(sceneData.textList[textIndex].effect),
          "sceneNo": sceneIndex,
          textNo:textIndex,
          "shadowInfo":shadow,
          languageCode: shopInfoContext.language
        }
        // console.log("request :: ", request)
        
        const response:any = await WebPostPreviewText(request);
    
        if(response.status===200){
          // const blob = await response.blob()
          const blob = new Blob([response.data],{type:"video/webm"})
          const url = await URL.createObjectURL(blob);
          textUrlList[sceneIndex].push(url);
        }else{
          textUrlList[sceneIndex].push('');
        }
      }
    }
    // console.log("text url List :: ", textUrlList);
    setTextVideoUrlList(textUrlList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[3]=false;
      return newState;
    })
  }

  async function getSceneVideo(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[4]=true;
      return newState;
    })    

    const newUrlList = [];
    for(let sceneIndex=0; sceneIndex<scenarioContext.finalScenario.scenes.length;sceneIndex++){

      const currentScene = scenarioContext.finalScenario.scenes[sceneIndex];
      const nextScene = scenarioContext.finalScenario.scenes[sceneIndex+1]

      const isLast = sceneIndex === scenarioContext.finalScenario.scenes.length-1

      const request = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        sceneNo: sceneIndex,
        screenBefore: currentScene.layoutList[0].path,
        screenAfter: isLast? "black": nextScene.layoutList[0].path,
        screenBeforeEffect: editorContext.screenEffectList[sceneIndex].before,
        screenAfterEffect:editorContext.screenEffectList[sceneIndex].after,
        effect: isLast? "none":matchEffect(currentScene.effect),
        duration: currentScene.time,
        durationAfter: isLast? 3 : nextScene.time,
        mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
        serverType: process.env.REACT_APP_SERVER_TYPE,
        inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
        shopName: shopInfoContext.shopName,
        isDev: false,
        languageCode:shopInfoContext.language,
        filter:currentScene.filter,
        nextFilter: isLast? currentScene.filter:nextScene.filter
      }
      // console.log("request :: ", request)
      
      const response:any = await WebPostPreviewScene(request);
  
      if(response.status===200){
        // const blob = await response.blob()
        const blob = new Blob([response.data],{type:"video/mp4"})
        const url = await URL.createObjectURL(blob);
        newUrlList.push(url);
      }else{
        newUrlList.push('');
      }
    }
    setSceneVideoUrlList(newUrlList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[4]=false;
      return newState;
    })
  }

  const onClickButton=()=>{
    // setClipData();
  }




  return (
    <>
    <EditorPageContext.Provider value={editorPageContextValue}>
      {
        isLoading &&
        <LoadingScreenWrapper>
          <LoadingScreen style={{zIndex:10000}} text={" "}/>
        </LoadingScreenWrapper>
      }
      {
        isLoadingData.every(value=>value===false)?
        <ThemeProvider>
          {/* <EditorWrapper> */}
            <StyledElementEditor className="element-editor">
              {/* <StyledHeaderWrapper> */}
                <Header step={props.step} setStep={props.setStep} backupData={backupScenario}/>
                <HeaderSub />
                <Main />
              {/* </StyledHeaderWrapper> */}
            </StyledElementEditor>
          {/* </EditorWrapper> */}
        </ThemeProvider>
        :
        <LoadingScreen text={t("pages-editor.c1")}/>
      }
      
    </EditorPageContext.Provider>
    </>
  );
};

export default Editor;

const LoadingScreenWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  justify-content: center;
  align-items:center;
  background-color:rgba(0,0,0,0.4);
`