import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState, useContext, memo } from "react";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../../editorPageContext";
// import { MasherContext } from "../../../../../Components/Masher";

interface ItemProps {
  text: string;
  duration: number;
  stepSize: number;
  onClick?: Function;
  selected?: boolean;
  clip?:any;
  scene?:any
}

const TextItem = ({ text, duration, stepSize, onClick=undefined, selected=false, clip=undefined, scene=undefined }: ItemProps): JSX.Element => {
  // const [txt, setTxt] = useState(text);
  // const {editor} = useContext(MasherContext)
  const [end, setEnd] = useState(duration * stepSize * 10);
  const [isSelected, setIsSelected] = useState(false);
  const editorPageContext = useContext(EditorPageContext);
  

  const handleClick = (e) => {
    // setIsSelected(prev=>!isSelected)
    editorPageContext.setSelectedItem({
      type:'text',
      data: text,
      scene: scene,
      // clip:clip
    })
    if(onClick){
      onClick();
    }
  }
  useEffect(()=>{
    setIsSelected(selected);
  },[selected])
  useEffect(()=>{
    setEnd(prev=>(duration * stepSize * 10))
  },[stepSize,duration])

  return (
    <Box display="flex" width={end} height={32} justifyContent="space-between" alignItems="center" pr={2} pl={2} py={0} position="relative" borderRadius={1} overflow="hidden" onClick={handleClick}>
      <Box position="absolute" width="100%" height="100%" top={0} left={0} bgcolor={isSelected? "var(--color-support-textbar-active, #A753FB)" : "var(--color-support-textbar, #CC9DFA)"} />
      <>
        {isSelected && (
        <Box sx={{ position: 'absolute', width: 12, height: "100%", top: 0, left: 0, cursor: "pointer", }}>
          <Box sx={{ position: 'absolute', width: 4, height: 12, top: 10, left: 4, bgcolor: "var(--color-background-primary, #FFF)", borderRadius: "var(--radius-full, 9999px)" }} /> 
        </Box>
        )}
        <Typography position="relative" flex={1} fontWeight="bold" color="white" fontSize="0.875rem" letterSpacing="0.1em" lineHeight="1.5" sx={{whiteSpace: "nowrap", overflow:"hidden", textOverflow:"ellipsis"}}> {text} </Typography>
      </>
      <>
        {isSelected && (
        <Box sx={{ position: 'absolute', width: 12, height: "100%", top: 0, right: 0, cursor: "pointer", }}>
          <Box sx={{ position: 'absolute', width: 4, height: 12, top: 10, left: 4, bgcolor: "var(--color-background-primary, #FFF)", borderRadius: "var(--radius-full, 9999px)" }} /> 
        </Box>
        )}
      </>
    </Box>
  )
}

interface Props {
  stepSize: number,
  onClickItem?: Function,
  clips?:any[]
}

export const TextTrack = ({ stepSize, onClickItem=undefined, clips=[] }: Props): JSX.Element => {

  const [textList, setTextList] = useState([]);
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);

  // const masherContext = useContext(MasherContext);
  // const {editor} = masherContext
  // const {selection} = editor
  
  const [textClips, setTextClips] = useState<any[]>([]);

  // useEffect(()=>{  
  //   if(selection?.mash?.clips){
  //     // let textClips = selection.mash.clips.filter((clip, index)=> (index!==0)&&((index%3)===0))
      
  //     // setTextClips(textClips)
  //   }
  // },[selection?.mash?.clips])

  useEffect(()=>{
    getText();
  },[])

  function getText(){
    if(scenarioContext.finalScenario?.scenes){
      const newTextList = scenarioContext.finalScenario.scenes.map(scene=>({
        duration: scene.time,
        text: scene.textList[0]
      }));
      setTextList(newTextList)
    }
  }

  const [sceneList, setSceneList] = useState([]);
  useEffect(()=>{
    const scenes = scenarioContext.finalScenario?.scenes;
    // 최종 시나리오의 장면 정보
    // scenes.no : 장면 번호
    // scenes.time : 장면 재생시간
    // scenes.title: 장면 제목
    // scenes.fileName : 장면에 해당하는 파일

    // console.log("scenes :: ", scenes)
    if(scenes){
      setSceneList(scenes);
      
    } 
  },[scenarioContext.finalScenario?.scenes])

  const handleOnClickItem = (index) => {
    if(onClickItem){
      if(editorPageContext.selectType==="text" && editorPageContext.selectIndex===index){
        onClickItem({type:undefined, index:undefined})
      }else{
        onClickItem({type:"text", index:index})
      }
    }
  }
  
  return (
    // <Box sx={{ width: "100%", height: "48px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
    // </Box>
    <Stack gap={0} direction="row" sx={{ height: "32px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px" }}>
      {
        sceneList.map((scene,index)=>{
          return(
            <TextItem key={`text-track${index}`} selected={(editorPageContext.selectType==="text")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleOnClickItem(index)}} text={scene.textList[0].text} duration={scene.time} stepSize={stepSize} scene={scene}/>    
          )
        })
      }
      {/* {
        textList.map((text, index)=>(
          <TextItem selected={(editorPageContext.selectType==="text")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleOnClickItem(index)}} text={text.text.text} duration={text.duration} stepSize={stepSize} />    
        ))
      } */}
      {/* {
        clips?.map((clip, index)=>{
          return(
            <TextItem selected={(editorPageContext.selectType==="text")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleOnClickItem(index)}} text={clip.container.string+`${index}`} duration={(clip.frames)/30} stepSize={stepSize} />    
          )
        })
      } */}
      {/* <TextItem text="자막" duration={5} stepSize={stepSize} />
      <TextItem text="자막 테스트" duration={7} stepSize={stepSize} /> */}
    </Stack>
  )
}

export const MemoizedTextTrack = memo(TextTrack);