import React from "react";

export interface shopCategoryInterface{
  title:string,
  value:number|string,
  label:string
}

export interface fileInfo{
  file:File,
  s3Key:string,
  mainCategory:string,
  subCategory:string,
  editInfo:any,
  presignedUrl: undefined|string,
}
interface ShopInfoContextInterface{
  hasAssetId:boolean,
  hasLoaded:boolean,
  completedStep:number,
  shopName:string,
  shopAddress:string,
  shopCall:string[],
  shopCategory:string,
  adTime:number,
  files:fileInfo[],
  editedFiles:fileInfo[],
  aiImages:string[],
  includeAiImage:boolean,

  isGenerating:boolean,

  imageAnalysisInfo:any,
  language:string,
  isNew:boolean,

  setHasAssetId:(newBoolean:boolean)=>void,
  setHasLoaded:(newBoolean:boolean)=>void,
  setCompletedStep: (newStep:number)=>void
  setShopName:(newName:string)=>void,
  setShopAddress:(newAddress:string)=>void,
  setShopCall:(newCall:string[])=>void,
  setShopCategory:(newCategory:string)=>void,
  setAdTime:(newTime:number)=>void,
  setIncludeAiImage:(include:boolean)=>void,
  
  addFile:(newFileInfo:fileInfo[])=>void,
  setEditedFiles:(newEditedFiles:fileInfo[])=>void,
  removeFile:(targetFileInfo:fileInfo)=>void,
  changeFile:(originalFile:fileInfo, changeFile:fileInfo)=>void,
  changeEditedFile:(originalFile:fileInfo, changeFile:fileInfo)=>void,
  setAiImages:(newUrlList:string[])=>void,

  setImageAnalysisInfo:(newInfo:any)=>void,
  setIsGenerating:(newVal:boolean)=>void,
  setLanguage:(lang:string)=>void,
  setIsNew:(newVal)=>void
}

const ShopInfoContextDefault:ShopInfoContextInterface = {
  hasAssetId:false,
  hasLoaded:false,
  completedStep:0,
  shopName:"",
  shopAddress:"",
  shopCall:["","",""],
  shopCategory:"",
  adTime:15,
  files:[],
  editedFiles:[],
  aiImages:[],
  includeAiImage:false,
  imageAnalysisInfo:undefined,
  isGenerating:false,
  language:"ko-kr",
  isNew:false,

  setHasAssetId:()=>{},
  setHasLoaded:()=>{},
  setCompletedStep:()=>{},
  setShopName:()=>{},
  setShopAddress:()=>{},
  setShopCall:()=>{},
  setShopCategory:()=>{},
  setAdTime:()=>{},
  setIncludeAiImage:()=>{},

  addFile:()=>{},
  setEditedFiles:()=>{},
  removeFile:()=>{},
  changeFile:()=>{},
  changeEditedFile:()=>{},
  setAiImages:()=>{},

  setImageAnalysisInfo:()=>{},
  setIsGenerating:()=>{},
  setLanguage:()=>{},
  setIsNew:()=>{}
}

export const ShopInfoContext = React.createContext(ShopInfoContextDefault);