import React from "react";

export type SelectType = undefined|"video"|"text"|"ntr"|"bgm"

interface SelectedBgmInfo{
  path:string,
  blobUrl:string,
  presignedUrl:string,
  title:string,
  duration:string,
}

interface TextRect{
  x:number,
  y:number,
  w:number,
  h:number,
  rotate:number,
}

interface EditorPageContextInterface{
  selectType: SelectType,
  selectIndex:number|undefined,
  selectedBgmInfo: SelectedBgmInfo,
  bgmUrlList:string[],
  bgmBlobs:any[],
  selectedItem: any,
  ntrActorList:string[],
  ntrUrlList:string[],
  textVideoUrlList:string[][],
  sceneVideoUrlList:string[],
  blankVideoUrl:string,
  totalDuration:number,
  isInpainting: boolean,
  aiEraserList:string[][],
  aiBgList:string[][],
  textRectList:any[],
  isLoading:boolean,
  backupScenario:any,
  backupBGMVolume:any,
  backupTTSVolume:any,
  backupShadows:any,
  backupScreenEffect:any,
  showAlert:boolean,
  alertMessage:any,
  alertConfirmFunc:any,
  alertCancelFunc:any,

  setSelectType:(newType:SelectType)=>void,
  setSelectIndex:(newIndex:number|undefined)=>void,
  setSelectedBgmInfo:(newInfo)=>void,
  setBgmUrlList:(newList)=>void,
  setBgmBlobs:(newBlobs)=>void,
  setSelectedItem:(newItem)=>void,
  setNtrActorList:(newList)=>void,
  setNtrUrlList:(newList)=>void,
  setTextVideoUrlList:(newList)=>void,
  setSceneVideoUrlList:(newList)=>void,
  setBlankVideoUrl:(newUrl)=>void,
  setTotalDuration:(newVal)=>void,
  setIsInpainting: (newVal)=>void,
  setAiEraserList:(newList)=>void,
  setAiBgList:(newList)=>void,
  setTextRectList:(newList)=>void,
  setIsLoading:(newVal)=>void,
  setBackupScenario:(newVal)=>void,
  setBackupBGMVolume:(newVal)=>void,
  setBackupTTSVolume:(newVal)=>void,
  setBackupShadows:(newVal)=>void,
  setBackupScreenEffect:(newVal)=>void,
  setShowAlert:(newVal)=>void,
  setAlertMessage:(newVal)=>void,
  setAlertConfirmFunc:(newFunc)=>void,
  setAlertCancelFunc:(newFunc)=>void,
}


const EditorPageContextDefault:EditorPageContextInterface={
  selectType: undefined,
  selectIndex: undefined,
  selectedBgmInfo: undefined,
  bgmUrlList:[],
  bgmBlobs:[],
  selectedItem:undefined,
  ntrActorList:[],
  ntrUrlList:[],
  textVideoUrlList:[],
  sceneVideoUrlList:[],
  blankVideoUrl:"",
  totalDuration:0,
  isInpainting:false,
  aiEraserList:[],
  aiBgList:[],
  textRectList:[],
  isLoading: false,
  backupScenario:undefined,
  backupBGMVolume:undefined,
  backupTTSVolume:undefined,
  backupShadows:undefined,
  backupScreenEffect:undefined,
  showAlert: false,
  alertMessage:undefined,
  alertConfirmFunc:undefined,
  alertCancelFunc:undefined,
  

  setSelectType:()=>{},
  setSelectIndex:()=>{},
  setSelectedBgmInfo:()=>{},
  setBgmUrlList:()=>{},
  setBgmBlobs:()=>{},
  setSelectedItem:()=>{},
  setNtrActorList:()=>{},
  setNtrUrlList:()=>{},
  setTextVideoUrlList:()=>{},
  setSceneVideoUrlList:()=>{},
  setBlankVideoUrl:()=>{},
  setTotalDuration:()=>{},
  setIsInpainting:()=>{},
  setAiEraserList:()=>{},
  setAiBgList:()=>{},
  setTextRectList:()=>{},
  setIsLoading:()=>{},
  setBackupScenario:()=>{},
  setBackupBGMVolume:()=>{},
  setBackupTTSVolume:()=>{},
  setBackupShadows:()=>{},
  setBackupScreenEffect:()=>{},
  setShowAlert:()=>{},
  setAlertMessage:()=>{},
  setAlertConfirmFunc:()=>{},
  setAlertCancelFunc:()=>{}
}

export const EditorPageContext = React.createContext(EditorPageContextDefault)