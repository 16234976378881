import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Popover, TextField, Switch, Button, Paper, Divider, Select, MenuItem, ToggleButtonGroup, toggleButtonGroupClasses, ToggleButton } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormatAlignLeft, FormatAlignRight, FormatAlignCenter, FormatAlignJustify, FormatBold, FormatItalic, FormatUnderlined }  from '@mui/icons-material'

import { ColorPicker } from '../../../../../../../Components/pages/Editor/ColorPicker';

import styled from "styled-components";

import fontDataJson from "../../../../../../../Data/fontData.json"
import { EditorPageContext } from "../../../../../editorPageContext";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { LabeledInputStepper } from "../../../../../../../Components/pages/Editor/LabeledInputStepper";
import { useTranslation } from "react-i18next";
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";
import { TextPanelContext } from "../../../TextPanelContext";
import { PanelContext } from "../../../../PanelContext";


const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  // justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: content-box;
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  width:"100%",
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: 0,
    border: 0,
    borderRadius: `theme.shape.borderRadius`,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: '1px solid transparent',
    },
}));


const TextAlignToggleButtons = ({value='left', onChange}): JSX.Element => {
  const [alignment, setAlignment] = React.useState<string | null>(value);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    setAlignment(newAlignment);
    onChange(newAlignment);
  };

  return (
    <StyledToggleButtonGroup
      value={alignment}
      exclusive
      onChange={handleAlignment}
      aria-label="text alignment"
      size="small"
    >
      <ToggleButton value="center" aria-label="centered">
        <FormatAlignCenter fontSize="small" color={alignment==="center"? "primary": "secondary"} />
        {/* <ReflectX width="16" height="16" color={alignment==="left"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
      <ToggleButton value="left" aria-label="left aligned">
        <FormatAlignLeft fontSize="small" color={alignment==="left"? "primary": "secondary"}/>
        {/* <ReflectY width="16" height="16" color={alignment==="center"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        <FormatAlignRight fontSize="small" color={alignment==="right"? "primary": "secondary"}/>
        {/* <RotateLeft width="16" height="16" color={alignment==="right"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
      <ToggleButton value="justify" aria-label="justified">
        <FormatAlignJustify fontSize="small" color={alignment==="justify"? "primary": "secondary"}/>
        {/* <RotateRight width="16" height="16" color={alignment==="justify"? "#3617CE": "secondary"}/> */}
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

const TextStyleToggleButtons = ({value='', onChange}): JSX.Element => {
  const [textStyle, setTextStyle] = React.useState<string | null>(value);

  const handleTextStyle= (
    event: React.MouseEvent<HTMLElement>,
    newStyle: string | null,
  ) => {
    setTextStyle(newStyle);
    onChange(newStyle);
  };
  
  return (
    <StyledToggleButtonGroup
      value={textStyle}
      exclusive
      onChange={handleTextStyle}
      aria-label="text style"
      size="small"
    >
      <ToggleButton value="bold" aria-label="bold">
        <FormatBold fontSize="small" color={textStyle==="bold"? "primary": "secondary"} />
      </ToggleButton>
      <ToggleButton value="italic" aria-label="italic">
        <FormatItalic fontSize="small" color={textStyle==="italic"? "primary": "secondary"}/>
      </ToggleButton>
      <ToggleButton value="underline" aria-label="underline">
        <FormatUnderlined fontSize="small" color={textStyle==="underline"? "primary": "secondary"}/>
      </ToggleButton>
    </StyledToggleButtonGroup>
  );
};

export const TextTabContent = ({index}): JSX.Element => {
  const { t } = useTranslation();
  const [textStyle, setTextStyle] = useState("");
  const [textAlign, setTextAlign] = useState('center');

  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const shopInfoContext = useContext(ShopInfoContext)
  const panelContext = useContext(PanelContext)

  const {textIndex, setTextIndex} = panelContext

  const [scene, setScene] = useState(undefined)

  const [selectedFont, setSelectedFont] = useState("에스코어드림5")
  const [selectedFontSize, setSelectedFontSize] = useState("100");
  const [color, setColor] = useState("#ffffff");

  const {textList, setTextList} = useContext(TextPanelContext);

  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      const idx = editorPageContext.selectIndex;
      const targetScene = scenarioContext.finalScenario.scenes[idx];
      setScene(targetScene);
      setSelectedFont(targetScene.textList[0].font)
      setSelectedFontSize(targetScene.textList[0].height.toString())
      setColor(targetScene.textList[0].fontColor)
    }
  },[editorPageContext.selectIndex,scenarioContext.finalScenario])

  const handleChangeX = (newValue: number | number[], index:number) => {
    const newX = parseFloat( newValue.toString() );    
    let tempList =JSON.parse(JSON.stringify(textList));
    tempList[index].posX = newX;
    setTextList(tempList);
  };

  const handleChangeY = (newValue: number | number[], index:number) => {
    const newY = parseFloat( newValue.toString() );
    let tempList =JSON.parse(JSON.stringify(textList));
    tempList[index].posY = newY
    setTextList(tempList);
  };

  const hideItem = true

  const handleChangeText = (event:any,index:number) => {
    // let newSceneData = JSON.parse(JSON.stringify(scene));
    // newSceneData.textList[index].text = event.currentTarget.value
    // setScene(newSceneData);

    let tempList =JSON.parse(JSON.stringify(textList));
    tempList[index].text = event.currentTarget.value;
    setTextList(tempList);    
  }

  const handleChangeFont = (event:any, index:number)=>{
    setSelectedFont(event.target.value);
    // let newSceneData = JSON.parse(JSON.stringify(scene));
    // newSceneData.textList[index].font = event.target.value
    // setScene(newSceneData);

    let tempList =JSON.parse(JSON.stringify(textList));
    tempList[index].font = event.target.value;
    setTextList(tempList);    
  }

  const handleChangeFontSize = (event:any, index:number)=>{
    setSelectedFontSize(event.target.value);
    // let newSceneData = JSON.parse(JSON.stringify(scene));
    // newSceneData.textList[index].height = event.target.value
    // setScene(newSceneData);

    let tempList =JSON.parse(JSON.stringify(textList));
    tempList[index].height = event.target.value;
    setTextList(tempList);    
  }

  const handleChangeColor = (value:string, index:number) => {
    setColor(value);
    // let newSceneData = JSON.parse(JSON.stringify(scene));
    // newSceneData.textList[index].fontColor = value
    // setScene(newSceneData);

    let tempList =JSON.parse(JSON.stringify(textList));
    tempList[index].fontColor = value;
    setTextList(tempList);    
  };

  useEffect(()=>{
    if(scene){
      const index = editorPageContext.selectIndex;
      let finalScenario = scenarioContext.finalScenario;
      finalScenario.scenes[index]=scene;
      scenarioContext.setFinalScenario(finalScenario);
    }
  },[scene])

  const handleClickTextItem = (index) => {
    setTextIndex(index);
  }

  return (
    <StyledFrame>
      {textList?.map((text, index)=>{
        return (
          <Accordion key={`text-property-${index}`} expanded={index===textIndex} sx={{ overflowX:"none"}} onClick={()=>{handleClickTextItem(index)}} >
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls={`panel2-content${index}`}
              id={`panel2-header${index}`}
              sx={{ width:"100%", maxWidth:"100%", padding:0, minHeight:21, height: 21,
                '& .MuiAccordionSummary-content':{width:"90%"}
              }}
            >
              <Typography variant="caption" color={textIndex==index?"#3617CE":"rgba(68,75,82,1)"} sx={{whiteSpace: "nowrap", textOverflow:"ellipsis", overflow:"hidden", width:"100%"}}>{`# ${text.text}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField variant="outlined" fullWidth rows={2} placeholder="Text" sx={{ borderColor: "#E2E4E7" }} value={text.text} onChange={(e)=>{handleChangeText(e,index)}}/>
              <Divider sx={{ width: "100%" }} />
              <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
                <AccordionSummary
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls="panel2-content"
                  id="panel2-header"
                  sx={{ padding:0, minHeight:21, height: 21 }}
                >
                  <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>{t("pages-editor-main-textpanel.c6")}</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingLeft:0, paddingRight:0, gap: 1 }} >
                  <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                    <LabeledInputStepper value={text.posX.toFixed(0)} text="X" width={140} onChange={(val:number)=>handleChangeX(val,index)}/>
                    <LabeledInputStepper value={text.posY.toFixed(0)} text="Y" width={140} onChange={(val:number)=>handleChangeY(val,index)}/>
                  </Box>
                  <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                    <Select
                      value={selectedFont}
                      defaultValue={t("pages-editor-main-textpanel.c5")} //defaultValue="에스코어드림5"
                      sx={{ flex: 1, height: "32px",  borderColor: "#E2E4E7" }}
                      onChange={(e)=>handleChangeFont(e,index)}
                      size="small"
                      IconComponent={ArrowDropDownIcon}
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {fontDataJson.map(font=>(<MenuItem sx={{fontFamily:`${font["font-family"]}`}} value={font.fontName}>{shopInfoContext.language==="ko-kr"?font.fontName:font.fontNameEn}</MenuItem>))}
                      {/* <MenuItem value={"Noto Sans KR"}>Noto Sans KR</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem> */}
                    </Select>
                    <Select
                      value={text.height}
                      defaultValue={"85"}
                      sx={{ flex: 1, height: "32px" }}
                      onChange={(e)=>handleChangeFontSize(e,index)}
                      size="small"
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {Array.from({ length: 151 }, (_, i) => (
                        <MenuItem key={i} value={50 + i}>
                          {50 + i}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <ColorPicker value={text.fontColor} onChange={(e)=>handleChangeColor(e, index)}/>
                  {
                    !hideItem &&
                    <Box pt={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                      <TextAlignToggleButtons value={textAlign} onChange={(val:string)=>setTextAlign(val)} />
                      <Divider flexItem orientation="vertical" sx={{ mx: 0.5, my: 1 }} />
                      <TextStyleToggleButtons value={textStyle} onChange={(val:string)=>setTextStyle(val)} />
                      {/* <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
                        <IconButton>
                        </IconButton>  
                      </Box> */}
                    </Box>    
                  }
                </AccordionDetails>
              </Accordion>
            </AccordionDetails>
          </Accordion>
        )
      })}
      
    </StyledFrame>
  );
};