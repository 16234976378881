import styled from "styled-components";
import Badge from "../../../common/Badge";
import Button from "../../../common/Button";
import Icon from "../../../Icon";

export const SceneFrameStyle = styled.div`
  align-items: center;
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-05"]};
  display: flex;
  flex-direction: row;
  gap: ${(props)=>props.theme.spacing["spacing-08"]};
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-13"]}`
  }};
  box-sizing: border-box;
  width:100%;
  position:relative;
  &.editing{
    flex-direction: row;
    padding:${(props)=>{
      const spacing = props.theme.spacing;
      return `${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-11"]} ${spacing["spacing-08"]}`
    }};
    box-sizing: border-box;
  }
`

export const ControlIcon = styled(Icon)`
  all:unset;
  &:hover{
    cursor:pointer;
  }
`

export const ContentsStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-09"]};
  box-sizing: border-box;
  width:100%;
`

export const SceneTopStyle = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;

  width: 100%;
`

export const SceneTopTextWrapperStyle = styled.div`
  align-self: stretch;
  color: ${(props)=>props.theme.colors["text-secondary"]};
  ${(props)=>props.theme.typography["pre-body-04"]};
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  flex:1;
`
  
export const SceneTopIndexStyle = styled.div`
  align-self: stretch;
  display:flex;
  color: ${(props)=>props.theme.colors["text-primary"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  gap:${(props)=>props.theme.spacing["spacing-04"]};
  margin-top: -1px;
  position: relative;
`
export const SceneTopTitleEditStyle = styled.div`
  color: ${(props)=>props.theme.colors["text-brand1"]}
`
export const SceneTopTitleStyle = styled.div`
  align-self: stretch;
  ${(props)=>props.theme.typography["pre-body-04"]};
  color:${(props)=>props.theme.colors["text-tertiary"]};
  position:relative;
  white-space: nowrap;
  
  @media (max-width: 1900px) { /* 600px 이하일 때 텍스트가 두 줄로 표시됨 */
    white-space: normal;
    word-break: break-word; /* 단어가 너무 길면 줄 바꿈 */
  }
`

export const SceneEditBtnsStyle = styled.div`
  align-items: center;
  display:inline-flex;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  position:relative;
`
export const SceneButtonStyle = styled(Button)`
  all:unset;
  box-sizing:border-box;
  border-radius: ${(props)=>props.theme.radius["radius-01"]};
  ${(props)=>props.theme.typography["pre-body-03"]}
  color:${(props)=>
    props.text==="완료"? props.theme.colors["text-brand1"] : props.theme.colors["text-tertiary"]
  };
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-02"]} ${spacing["spacing-none"]} ${spacing["spacing-02"]}`
  }};
  cursor:pointer;
  width:36px;
`

//frame2 
export const SceneConetentsStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;
  width:100%;
`
//frame3
export const SceneConetentsItemStyle = styled.div`
  align-items: center;
  display: flex;
  gap: ${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;
  width:100%;
`

//p
export const SceneConetentsFrameTextStyle = styled.div`
  align-self:stretch;
  ${(props)=>props.theme.typography["pre-body-04"]};
  color:${(props)=>props.theme.colors["text-primary"]};
  position: relative;

  &.disabled{
    opacity: 0.3;
  }
`

export const SceneBadgeStyle = styled.div`
  align-items:center;
  justify-contents: center;
  width: 80px;
  min-width:80px;
`