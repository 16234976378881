import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { VideoTabContainer, SceneTransitionTabContainer, AIStyleTabContainer, ScreenEffectTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";
import { EditorPageContext } from "../../editorPageContext";
import { EditingPanelContext } from "./EditingPanelContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import Button from "../../../../Components/common/Button";
import { EditorContext } from "../../../../Contexts/EditorContext";
import screenEffectJson from "../../../../Data/screenEffect.json"
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import { WebPostPreviewScene } from "../../../../Api/resource";
import LoadingScreen from "../../../../Components/common/LoadingScreen";
import { matchEffect } from "../../../../Utilities";
import { PanelContext } from "../PanelContext";

const StyledEditingPanel = styled.div`
  position: relative;
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  padding: var(--tokens-spacing-07-duplicate) var(--tokens-spacing-11-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-07-duplicate);
  width: 336px;
`;

export const EditingPanel = (): JSX.Element => {
  
  const [selectNum, setSelectNum] = useState(0);

  const {filter, setFilter, showInpainting} = useContext(PanelContext);

  useEffect(()=>{
    if(showInpainting){
      setSelectNum(3)
    }
  },[showInpainting])

  // const [filter, setFilter] = useState({name:"none", intensity:1});
  
  const handleSelect = (num: number) => {
    setSelectNum(num);
  }
  
  const editorPageContext = useContext(EditorPageContext)
  const scenarioContext = useContext(ScenarioContext)
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)
  const [selectedSceneIndex, setSelectedSceneIndex] = useState(undefined);
  useEffect(()=>{setSelectedSceneIndex(editorPageContext.selectedItem?.index)},[editorPageContext.selectedItem])

  useEffect(()=>{
    const sceneIndex = editorPageContext.selectIndex;
    if(sceneIndex!==undefined){
      const scene = scenarioContext.finalScenario.scenes[sceneIndex];
      const filter = scene.filter;
      setFilter(filter);
    }
  },[editorPageContext.selectIndex, scenarioContext.finalScenario])

  return (
    <StyledEditingPanel>
      <TabHeader selectNum={selectNum} onClick={handleSelect}/>
      {selectNum==0 &&<VideoTabContainer index={selectedSceneIndex}/>}
      {selectNum==1 &&<SceneTransitionTabContainer index={selectedSceneIndex}/>}
      {selectNum==2 &&<ScreenEffectTabContainer index={selectedSceneIndex}/>}
      {selectNum==3 &&<AIStyleTabContainer index={selectedSceneIndex}/>}
    </StyledEditingPanel>
  );
};

const LoadingScreenWrapper = styled.div`
  background-color:rgba(0,0,0,0.5);
  position: fixed;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
`