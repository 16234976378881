import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper, Stack, ButtonProps, ButtonTypeMap, ExtendButtonBase } from "@mui/material";
import styled, { withTheme } from "styled-components";
import { SceneToggleButtons } from "../../../../../../../Components/pages/Editor/SceneToggleButtons";
import { EditorPageContext } from "../../../../../editorPageContext";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
//import { ThemeProvider } from "../../../../../../../theme/ThemeProvider";
import theme from "../../../../../../../styles/theme";

import screenEffectJson from "../../../../../../../Data/screenEffect.json"
import { IconListItem } from "../../../../../../../Components/pages/Editor/IconListItem";
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const SceneButton = styled((props: ButtonProps) => (
  <Button variant="outlined" sx={{ borderColor: 'divider', padding: 0, whiteSpace: 'normal' }} {...props} />
))((theme) => {
  return {
  width: 90.33,
  height: 56,
  // display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
}});

export const SceneTransitionTabContent = ({index}): JSX.Element => {
  
  const [transition, setTransition] = useState('오른쪽으로 슬라이드');
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const shopInfoContext = useContext(ShopInfoContext)
  const [prevSrc, setPrevSrc] = useState(`./AI_transition_slide_right.mp4`)
  // {"effect":"오른쪽으로 슬라이드", "name":"slide_right"},
  // {"effect":"왼쪽으로 슬라이드", "name":"slide_left"},
  // {"effect":"위로 슬라이드", "name":"slide_up"},
  // {"effect":"아래로 슬라이드", "name":"slide_down"},
  // {"effect":"원 화면전환", "name":"circle"},
  // {"effect":"물결 화면전환", "name":"wave"},
  // {"effect":"페이드아웃 화면전환", "name":"fadein"},
  // {"effect":"없음", "name":"none"}


  useEffect(()=>{
    setTransition(scenarioContext.finalScenario.scenes[index].effect);
  },[index, scenarioContext.finalScenario.scenes[index]])

  useEffect(()=>{
    const idx = screenEffectJson.findIndex((effect)=>effect.effect===transition)
    if(idx>=0){
      setPrevSrc(`./AI_transition_${screenEffectJson[idx].name}.mp4`)
    }
    if(transition==="없음"){
      setPrevSrc(undefined)
    }

  },[transition])

  const handleChangeTransition = (newEffect:any ) => {
    let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
    finalScenario.scenes[index].effect=newEffect.effect;
    scenarioContext.setFinalScenario(finalScenario);
  };

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "왼쪽으로 슬라이드":
        enName="Slide left"
        break;
      case "오른쪽으로 슬라이드":
        enName="Slide right"
        break;
      case "위로 슬라이드":
        enName="Slide up"
        break;
      case "아래로 슬라이드":
        enName="Slide down"
        break;
      case "원 화면전환1":
        enName="Circle 1"
        break;
      case "원 화면전환2":
        enName="Circle 2"
        break;
      case "페이드아웃 화면전환":
        enName="Fade out"
        break;
      case "없음":
        break;
    }
    return enName;
  }

  return (    
    <StyledFrame>
      <Stack gap={1} direction="row" sx={{ flexWrap: 'wrap'}}>
        <IconListItem  key={`screen-effect-index`} src={prevSrc} Icon={null} name={translate(transition)} onCommand={()=>{}}/>
      </Stack>
    </StyledFrame>
  );
};
