import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Spiner from "../Spiner";
import Lottie from 'lottie-react';
import spinnerAnimation from "../../../animation/spinner.json"
import { useTranslation } from "react-i18next";

interface LoadingScreenProp{
  text?:string,
  style?:object
}

function LoadingScreen({text=undefined, style=undefined}:LoadingScreenProp){
  const { t } = useTranslation();
  return(
    <LoadingScreenStyle style={{...style}}>
      <Lottie
        animationData={spinnerAnimation}
        loop={true}
      />
      <TextWrapper>
        {text||t("pages-shopinfo.m1")}
      </TextWrapper>
    </LoadingScreenStyle>
  )
}

export default LoadingScreen

const LoadingScreenStyle = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;

  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;

  user-select:none;
  gap:${(props)=>props.theme.spacing["spacing-07"]};
`
const TextWrapper = styled.div`
  ${(props)=>props.theme.typography["pre-body-03"]};
  color:${(props)=>props.theme.colors["text-brand2"]};
`