import React, { useEffect, useRef, useState, useCallback } from "react";
import Icon from "../../../Icon";

import styled from "styled-components";

function VideoTimeline(props){

  const minDuration = 5;

  const [isDraggingStart, setIsDraggingStart] = useState(false);
  const [isDraggingEnd, setIsDraggingEnd] = useState(false);

  const [startTime, setStartTime] = useState(props.startTime);
  const [endTime, setEndTime] = useState(props.endTime);

  const frameRef = useRef(null);

  const [isEditable, setIsEditable] = useState(true);


  useEffect(()=>{
    console.log("change start or end time", startTime, endTime );
    // if(startTime===0 && endTime===props.timelineDuration){
    //   setIsDefault(true);
    // }else{
    //   setIsDefault(false);
    //   props.onChange(startTime, endTime);
    // }
    
    //props.onChange(startTime, endTime);
    
  },[startTime, endTime])

  useEffect(()=>{
    console.log("video duration to", props.timelineDuration);

    if(props.timelineDuration <= minDuration) {
      setIsEditable(false);
    } else {
      setIsEditable(true);
      setStartTime(0);
      setEndTime(minDuration);
    }

    
    // if( props.timelineDuration < minDuration) {
    //   setMinDuration(props.timelineDuration);
    // } 
    
  },[props.timelineDuration])

  const [headPosition, setHeadPosition] = useState('0%');

  useEffect(() => {
    //const duration = endTime - startTime;
    const videoEl = props.previewVideoRef.current as HTMLVideoElement;
    if (videoEl && props.timelineDuration > 0) {
      let newLeft;
      if (props.timelineDuration !== minDuration)
        newLeft = `${(props.currentTime / props.timelineDuration) * 100}%`;
      else {  // 전체 영상이 5초 이거나 자르기 한 이후
        newLeft = `${((props.currentTime - startTime) / props.timelineDuration) * 100}%`;
        //console.log('head postion', newLeft, props.currentTime, props.timelineDuration, startTime);
      }
      setHeadPosition(newLeft);
    }

  }, [props.currentTime]);

  useEffect(()=>{
    if(props.show) {
     // props.onChange(startTime, endTime);
     console.log("----timeline start time", startTime, endTime);
    } else {

    } 
  },[props.show]);

  useEffect(()=>{
    const videoEl = props.previewVideoRef.current as HTMLVideoElement
    if(props.show) {
      
      if(isDraggingStart || isDraggingEnd) {
        if(!videoEl.paused)
          videoEl.pause();
      } else {
        if(videoEl.paused)
          props.onChange(startTime, endTime);
          videoEl.play();
      }
    }
  },[isDraggingStart, isDraggingEnd]);

  const handleOnMouseDownStart = () => {
    setIsDraggingStart(true);
    // console.log("start drag start")
  }
  const handleOnMouseUpStart = () => {
    setIsDraggingStart(false);
    // console.log("stop drag start")
  }

  const handleOnMouseDownEnd = () => {
    setIsDraggingEnd(true);
    // console.log("start drag end")
  }
  const handleOnMouseUpEnd = () => {
    setIsDraggingEnd(false);
    // console.log("stop drag end")
  }

  const handleEndDrag = useCallback(()=>{
    setIsDraggingStart(false);
    setIsDraggingEnd(false);
    // console.log("stop drag")
  },[])


  const handleMouseMove = (e) => {
    const frame = frameRef.current as HTMLElement;
    const rect = frame.getBoundingClientRect();

    const videoEl = props.previewVideoRef.current as HTMLVideoElement

    if(videoEl){
      const current = (e.clientX - rect.x)/rect.width * videoEl.duration;
      // console.log("current ",current)
  
      if(isDraggingStart && current<endTime){
        const newStartTime = Math.max(current, 0);
        // console.log("current time start ::: ", newTime);
        const newEndTime = newStartTime + minDuration;

        if(newEndTime <= props.timelineDuration) {
          setStartTime(prev=>newStartTime);
          setEndTime(prev=>newEndTime);
        }
      }
  
      if(isDraggingEnd && startTime<current){
        const newEndTime = Math.min(current, videoEl.duration)
        const newStartTime = newEndTime - minDuration;
        // console.log("current time End ::: ", newTime);
        if(newStartTime >= 0) {
          setEndTime(prev=>newEndTime);
          setStartTime(prev=>newStartTime);
        }
      }
    }

  }

  return (
    <VideoTimelineStyle {...props}
      onMouseLeave={handleEndDrag}
      onMouseMove={handleMouseMove}
    >
      <VideoTimelineFrameStyle
        ref={frameRef}
        onMouseUp={handleEndDrag}
      >
        <ThumbnailWrapperStyle>
          {props.thumbnails?.map((thumbnail, index)=>{
            return(
              <VideoTimelineThumbnailStyle
                key={`thumbnail-${index}`}
                src={thumbnail}
                alt="thumb"
                draggable={false}
                onContextMenu={(e:any)=>{e.preventDefault()}}
              />    
            )
          })}
        </ThumbnailWrapperStyle>
        <VideoTimelinePlayHead {...props} style={{left: headPosition}}/>
        {isEditable&&<VideoTimelineDimFrame>
          <VideoTimelineDimStyle {...props} segmentStart={startTime} className="left"/>
          <VideoTimelineDimStyle {...props} segmentEnd={endTime} className="right"/>
        </VideoTimelineDimFrame>}
        <VideoTimelineBtnStyle
          {...props}
          left={0}
          segmentStart={startTime}
          segmentEnd={endTime}
          onMouseDown={handleOnMouseDownStart}
          onMouseUp={handleOnMouseUpStart}
          className={`left ${!isEditable?"":"active"}`}
        >
          <Icon
            icon="CaretLeft"
            color={!isEditable?"icon-secondary":"icon-primary-inverse"}
            width="8px"
            height="16px"
          />
        </VideoTimelineBtnStyle>
        <VideoTimelineBtnStyle
          {...props}
          right={0}
          segmentStart={startTime}
          segmentEnd={endTime}
          onMouseDown={handleOnMouseDownEnd}
          onMouseUp={handleOnMouseUpEnd}
          className={`right ${!isEditable?"":"active"}`}
        >
          <Icon
            icon="CaretRight"
            color={!isEditable?"icon-secondary":"icon-primary-inverse"}
            width="8px"
            height="16px"
          />
        </VideoTimelineBtnStyle>
      </VideoTimelineFrameStyle>
    </VideoTimelineStyle>
  )
}

export default VideoTimeline

const VideoTimelineStyle = styled.div`
  align-items: center;
  border: ${(props)=>props.theme.border["border-03"]} solid;
  background-color: ${(props)=>props.theme.colors["background-quarternary"]};
  border-color: ${(props)=>props.theme.colors["background-quarternary"]};
  border-radius: ${(props)=>props.theme.radius["radius-02"]};
  display: flex;
  overflow:hidden;
  position: relative;
  box-sizing:border-box;
  height: 56px;
  width: 100%;
    padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-06"]} ${spacing["spacing-none"]} ${spacing["spacing-06"]}`
  }};

  user-select:none;
  user-drag:none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`

const ThumbnailWrapperStyle = styled.div`
  width: 100%;
  height: 100%;
  display:flex;
  flex-direction: row;
  overflow:hidden;
`

const VideoTimelineBtnStyle = styled.div`
  align-items:center;
  justify-content:center;
  display: flex;
  position: absolute;  
  width: 12px;
  height: 100%;
  cursor:pointer;

  &.left{
    left: ${(props:any)=> (props.segmentStart/props.timelineDuration*100)}%;    
    z-index:2;
    transform: translate(-12px, 0px);
    border-radius:${(props)=>props.theme.radius["radius-02"]} 0 0  ${(props)=>props.theme.radius["radius-02"]};
  }

  &.right{
    transform: translate(12px, 0px);
    right: ${(props:any)=> `${(1-(props.segmentEnd/props.timelineDuration))*100}%`}; 
    border-radius: 0 ${(props)=>props.theme.radius["radius-02"]} ${(props)=>props.theme.radius["radius-02"]} 0;
  }



  &.active{
    background-color: ${(props:any)=>{
      const segmentLength = props.segmentEnd - props.segmentStart;
      if(segmentLength>5){
        return `${props.theme.colors["support-error"]}`
      }else{
        return `${props.theme.colors["button-brand1"]}`
      }
    }};

  }
`

const VideoTimelineFrameStyle = styled.div`
  user-select:none;
  align-items:center;
  display: inline-flex;
  flex: 0 0 auto;
  position: relative;
  width:100%;


  user-select:none;
  user-drag:none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`

const VideoTimelineThumbnailStyle = styled.img`
  height: 56px;
  object-fit: cover;
  position:relative;
  width: 83px;

  user-select:none;
  user-drag:none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`
const VideoTimelineDimFrame = styled.div`

  display: flex;  
  align-items: center;
  position: absolute;
  width:100%;
  box-sizing:border-box;
  background-color: green;
  justify-content: space-between;
`

const VideoTimelineDimStyle = styled.div`
  background-color:${(props)=>props.theme.colors["background-overlay-subtle"]};
  height:56px;
  position:absolute;

  &.left{
    left: 0px;
    width:${(props:any)=> (props.segmentStart/props.timelineDuration*100)}%;    
  }

  &.right{
    right: 0px;
    width: ${(props:any)=> ((props.timelineDuration-props.segmentEnd)/props.timelineDuration*100)}%; 
  }
`

const VideoTimelinePlayHead = styled.div`
  background-color: ${(props)=>props.theme.colors["background-primary"]};
  border-radius:${(props)=>props.theme.radius["radius-full"]};
  box-shadow: 0px 0px 4px 1px #00000033;
  height: 50px;
  position:absolute;
  top: 3px;
  width: 4px;
`
