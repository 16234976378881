import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { TextTabContainer, EffectTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";
import { EditorPageContext } from "../../editorPageContext";
import { IndeterminateCheckBoxRounded } from "@mui/icons-material";
import Button from "../../../../Components/common/Button";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";

import fontData from "../../../../Data/fontData.json"
import textEffectData from "../../../../Data/textEffect.json" 
import LoadingScreen from "../../../../Components/common/LoadingScreen";
import { useTranslation } from "react-i18next";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import { WebPostPreviewText } from "../../../../Api/resource";
import { TextPanelContext } from "./TextPanelContext";
import { PanelContext } from "../PanelContext";

const StyledTextPanel = styled.div`
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  // left: 1104px;
  // height: 400px;
  // min-height: 400px;
  padding: var(--tokens-spacing-07-duplicate) var(--tokens-spacing-11-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-07-duplicate);
  position: relative;
  // top: 0px;
  width: 336px;
  height: 100%;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: ${(props)=>props.theme.spacing["spacing-04"]};
  // right: ${(props)=>props.theme.spacing["spacing-14"]};
  right: 46px;
`

export const TextPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectNum, setSelectNum] = useState(0);
  const {isLoading, setIsLoading} = useContext(EditorPageContext);
  const textPanelContext = useContext(TextPanelContext);

  const handleSelect = (num: number) => {
    setSelectNum(num);
  }

  const editorPageContext = useContext(EditorPageContext)
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext);
  const {textRendered, setTextRendered} = useContext(PanelContext)
  const {storedTextList, setStoredTextList} = useContext(PanelContext)

  const [selectedSceneIndex, setSelectedSceneIndex] = useState(undefined);
  useEffect(()=>{
    // console.log("text pannel ", editorPageContext.selectedItem?.index);
    if(editorPageContext.selectIndex !== undefined){
      setSelectedSceneIndex(editorPageContext.selectIndex)}
    }
  ,[editorPageContext.selectIndex])

  function matchFont(target){
    // console.log("font target :: ", target);
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }
  function matchTextEffect(target){
    // console.log("target :: ",target)
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      return textEffectData[index].name
    }
  }
  
  function isSameObj(obj1, obj2){
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    // 키 개수가 다르면 다르다
    if (keys1.length !== keys2.length) {
      return false;
    }
  
    // 키와 값이 모두 같은지 확인
    return keys1.every(key => 
      obj2.hasOwnProperty(key) && obj1[key] === obj2[key]
    );
  }

  const handleClickApply = async () => {
    if(editorPageContext.selectType==="text"&&editorPageContext.selectIndex!=undefined){
      try{
        setIsLoading(prev=>true)
        setTextRendered(prev=>false);
        
        const index = editorPageContext.selectIndex;
        // console.log(scenarioContext.finalScenario.scenes[index].textList);
        // console.log(textList);
        // console.log(editorContext.sceneTextShadowEffectList[index]);
        // console.log(shadowList)

        let tempTextList = JSON.parse(JSON.stringify(storedTextList));
  
        for(let textIndex=0; textIndex<textList.length; textIndex++){
          const isSame = isSameObj(scenarioContext.finalScenario.scenes[index].textList[textIndex], storedTextList[index][textIndex]);
          const isSameEffect = isSameObj(editorContext.sceneTextShadowEffectList[index][textIndex], shadowList[textIndex])
          console.log(`text:${isSame} / effect:${isSameEffect}`)
          if(!isSame || !isSameEffect){

            tempTextList[index][textIndex] = JSON.parse(JSON.stringify(scenarioContext.finalScenario.scenes[index].textList[textIndex]));
            // console.log(`Rerender text ${textIndex}`);
            const sceneData = scenarioContext.finalScenario.scenes[index]
            // console.log("sceneData :: ", sceneData)
            const shadow = shadowList[textIndex];
            const request = {
              "assetId":sessionStorage.getItem("ASSET_ID"),
              "text": textList[textIndex].text,
              "fontSize": textList[textIndex].height,
              "textWidth":450,
              "fontFile": matchFont(textList[textIndex].font),
              "fontColor": textList[textIndex].fontColor,
              "hasShadow": sceneData.fileName==="search"?false:true,
              "posX": textList[textIndex].posX,
              "posY": textList[textIndex].posY,
              "duration": scenarioContext.finalScenario.scenes[index].time,
              "rotate": textList[textIndex].rotate||0,
              "textEffect": textList[textIndex].effect==="search"?"search":matchTextEffect(textList[textIndex].effect),
              "sceneNo": editorPageContext.selectIndex,
              "textNo": textIndex,
              "shadowInfo": shadow,
              languageCode: shopInfoContext.language      
            }
            
            const response:any = await WebPostPreviewText(request);
        
            if(response.status===200){
              // console.log('got file');
              const blob = new Blob([response.data],{type:"video/webm"})
              const url = URL.createObjectURL(blob);
              let urlList = JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
              urlList[editorPageContext.selectIndex][textIndex]=url;
              editorPageContext.setTextVideoUrlList(urlList)


            }          
          }else{
            // console.log(`text ${textIndex} is same`)
          }
        }
  
        let tempScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
        tempScenario.scenes[index].textList = textList;
        scenarioContext.setFinalScenario(tempScenario);

        let tempShadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
        tempShadowList[index] = shadowList;
        editorContext.setSceneTextShadowEffectList(tempShadowList);

        setStoredTextList(tempTextList);

        setTextRendered(prev=>true);
        setIsLoading(prev=>false)
      }catch(err){
        console.error(`RerenderError ${err}`);
        setIsLoading(prev=>false)
      }
    }else{
      console.error("select type none text / index undefined")
    }
  }

  const [textList, setTextList] = useState([]);
  const [shadowList, setShadowList] = useState([]);

  const textPanelContextValue={
    textList:textList,
    shadowList:shadowList,
    setTextList:(newList) => {setTextList(newList)},
    setShadowList: (newList)=>{setShadowList(newList)}
  }

  useEffect(()=>{
    if(editorPageContext.selectedItem!==undefined){
      // setTextData();
      setShadowData();
    }
  },[])
  useEffect(()=>{
    if(editorPageContext.selectedItem==="text"){
      setTextData();
      setShadowData();
    }
  },[editorPageContext.selectedItem])
  useEffect(()=>{
    if(editorPageContext.selectIndex !== undefined){
      if(editorPageContext.selectType==="text"){
        setTextData();
        setShadowData();
      }
    }
  },[editorPageContext.selectIndex])  

  async function setTextData(){
    if(editorPageContext.selectIndex!==undefined){
      const sceneData = JSON.parse(JSON.stringify(scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]));
      const textData = sceneData.textList;
      // console.log("set text list :: ",textData )
      setTextList(textData);
    }
  }
  async function setShadowData(){
    if(editorPageContext.selectIndex!==undefined){
      const shadowData = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]));
      setShadowList(shadowData);
    }
  }
  useEffect(()=>{
    if(editorPageContext.selectIndex!==undefined){
      setTextData();
      setShadowData();
    }
  },[scenarioContext.finalScenario])

  return (
    <TextPanelContext.Provider value={textPanelContextValue}>
      <StyledTextPanel>
        <TabHeader selectNum={selectNum} onClick={handleSelect}/>
        {selectNum==0 &&<TextTabContainer index={selectedSceneIndex}/>}
        {selectNum==1 &&<EffectTabContainer index={selectedSceneIndex}/>}
        <ButtonWrapper>
          {editorPageContext.selectType==="text"&&
            <Button
              $buttonType="text-s"
              $variant="brand1-subtle"
              text={t("com-pages-editor.apply")}
              onClick={handleClickApply}
            />
          }
        </ButtonWrapper>
      </StyledTextPanel>
    </TextPanelContext.Provider>
  );
};
