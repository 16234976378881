import { createContext } from "react";

const PanelContextDefault = {
  filter:{name:"none",intensity:1},
  textIndex: 0,
  showInpainting:false,
  inpaintingCmd:"remobj",
  textRendered: false,
  isDragRect:false,
  storedTextList:[],

  setTextIndex: (newIndex)=>{},
  setFilter: (newFilter)=>{},
  setShowInpainting: (newFilter)=>{},
  setInpaintingCmd: (newVal)=>{},
  setTextRendered: (newVal)=>{},
  setIsDragRect: (newVal)=>{},
  setStoredTextList: (newList)=>{}
}

export const PanelContext = createContext(PanelContextDefault);