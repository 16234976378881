import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { EditingPanel } from "./EditingPanel";
import { EffectPanel } from "./EffectPanel";
import { SectionPlayer } from "./SectionPlayer";
import { TimelinePanel } from "./TimelinePanel";
import { MusicPanel } from "./MusicPanel";
import { InfoPanel } from "./InfoPanel";
import { TextPanel } from "./TextPanel";
import { NarrationPanel } from "./NarrationPanel"
import { ScenarioContext } from "../../../Contexts/ScenarioContext";

import { EditorPageContext } from "../editorPageContext";
import { PanelContext } from "./PanelContext";

import { TutorialTooltip } from "../../../Components/pages/Editor/TutorialTooltip";

import { Trans, useTranslation } from "react-i18next";
import { EditorContext } from "../../../Contexts/EditorContext";

const StyledMain = styled.div`
  // height: 680px;
  // left: 0;
  // position: absolute;
  // top: 120px;
  // width: 1440px;

  display: flex;
  flex-grow: 1;
  width: 100%;
  // height:100%;
  height:calc(100% - 286px);
  position: relative;
  
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position:relative;
  height: 100%;
  // width:100vw;
`


export const Main = (): JSX.Element => {
  const {t} = useTranslation()

  const [textIndex, setTextIndex] = useState(0);
  const [filter, setFilter] = useState({name:"none", intensity:1})
  const [showInpainting, setShowInpainting] = useState(false);
  const [inpaintingCmd, setInpaintingCmd] = useState("remobj")
  const [textRendered, setTextRendered] = useState(false);
  const [isDrageRect, setIsDragRect] = useState(false);
  const [storedTextList, setStoredTextList] = useState([]);

  const scenarioContext = useContext(ScenarioContext)
  const editorPageContext = useContext(EditorPageContext)
  const {seenEditorTutorial, setSeenEditorTutorial} = useContext(EditorContext)

  useEffect(()=>{
    const finalSceneData = scenarioContext.finalScenario
    const list = finalSceneData.scenes.map(scene=>scene.textList);
    setStoredTextList(list);
  },[])


  const [panel, setPanel] = useState<any>();

  const panelContextValue = {
    textIndex:textIndex,
    filter:filter,
    showInpainting:showInpainting,
    inpaintingCmd:inpaintingCmd,
    textRendered:textRendered,
    isDragRect: isDrageRect,
    storedTextList: storedTextList,
    
    setTextIndex:(newIndex)=>{setTextIndex(newIndex)},
    setFilter:(newFilter)=>{setFilter(newFilter)},
    setShowInpainting:(newVal)=>{setShowInpainting(newVal)},
    setInpaintingCmd:(newVal)=>{setInpaintingCmd(newVal)},
    setTextRendered: (newVal)=>{setTextRendered(newVal)},
    setIsDragRect: (newVal)=>{setIsDragRect(newVal)},
    setStoredTextList: (newList)=>{setStoredTextList(newList)}
  }

  useEffect(()=>{
    switch(editorPageContext.selectType){
      case "video":
        setPanel(<EditingPanel />)
        break;
      case "text":
        setPanel(<TextPanel />)
        break;
      case "ntr":
        setPanel(<NarrationPanel />)
        break;
      case "bgm":
        setPanel(<MusicPanel />)
        break;
      default:
        setPanel(<InfoPanel />)
        break;
    }
  },[editorPageContext.selectType])

  return (
    <MainWrapper>
      <StyledMain>
        <PanelContext.Provider value={panelContextValue}>
          <EffectPanel />
          <SectionPlayer />
          {/* <InfoPanel /> */}
          {/* <EditingPanel /> */}
          {/* <TextPanel /> */}
          {/* <NarrationPanel /> */}
          {/* <MusicPanel /> */}
          {panel}
        </PanelContext.Provider>
      </StyledMain>
      <TimelinePanel />
      {
        !seenEditorTutorial && 
        <>
          <Dim/>
          <TutorialTooltip />
        </>
      }
    </MainWrapper>
  );
};

const Dim = styled.div`
  position:fixed;
  width:100vw;
  height:100vh;
  top:0px;
  left:0px;
  background-color:rgba(0,0,0,0.4);
  z-index:9000;
`
