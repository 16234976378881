import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Box, IconButton, Slider, Stack } from "@mui/material";

import { ReactComponent as ControlForwardIcon } from '../../../../Assets/icon-editor/player-control-forward.svg';
import { ReactComponent as ControlBackwardIcon } from '../../../../Assets/icon-editor/player-control-backwack.svg';
import { ReactComponent as ControlPlayIcon } from '../../../../Assets/icon-editor/player-control-play.svg';
import { ReactComponent as ControlStopIcon } from '../../../../Assets/icon-editor/player-control-stop.svg';
import { ReactComponent as ControlPauseIcon } from '../../../../Assets/icon-editor/player-control-pause.svg';
import { ReactComponent as PauseIcon } from '../../../../Assets/icon-editor/Pause.svg';
import { ReactComponent as ControlSoundOnIcon } from '../../../../Assets/icon-editor/player-control-sound-on.svg';
import { ReactComponent as ControlSoundOffIcon } from '../../../../Assets/icon-editor/player-control-sound-off.svg';
import { ReactComponent as ControlFocusIcon } from '../../../../Assets/icon-editor/player-control-focus.svg';
import { EditorContext } from "../../../../Contexts/EditorContext";

import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../editorPageContext";
import { WebGetUrl } from "../../../../Api/resource";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import theme from "../../../../styles/theme";
import fontData from "../../../../Data/fontData.json"
import templateData from "../../../../Data/templateData.json" 
import { PanelContext } from "../PanelContext";
// import { testVideo } from "../../../../../test.mp4";


const SectionPlayerContainer = styled.div`
  // height: 400px;
  // left: 432px;
  // position: absolute;
  // top: 0;
  // width: 672px;
  // background-color: #ffffff;
  flex-grow: 1;
  background-color: #ffffff;
  padding: 16px 16px 16px 16px;
  box-sizing:border-box;
  flex:1;
  overflow:hidden;
  height:300px;
  height:100%;
`;

const PlayerBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  position:relative;
  box-sizing:border-box;
`;

const PlayerStyle= styled.video`
  background-color: #f0f2f3;
  object-fit: fill;
  display:hidden;
  width: 2px;
  height: 100%;
  position:relative;
`

const PreviewWrapper = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color:transparent;
  box-sizing:border-box;

  max-height: calc(100% * (16 / 9));
`
const PreviewStyle = styled.div`
  // border: 1px solid red;
  position: relative;  
  box-sizing:border-box;
  height:100%;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index:21;
`
const PreviewVideoElement = styled.video`
  // border: 1px solid red;
  position: absolute;
  aspect-ratio: 16/9;
  z-index:8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  height:100%;
  max-height:100%;
  max-width:100%;
  pointer-events: none;
`

const HiddenVideo= styled.video`
  position:relative;
  display: none;
`

const Frame32 = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
`;

const TextWrapper21 = styled.div`
  color: var(--tokens-color-text-primary-duplicate);
  font-family: var(--pre-body-04-font-family);
  font-size: var(--pre-body-04-font-size);
  font-style: var(--pre-body-04-font-style);
  font-weight: var(--pre-body-04-font-weight);
  letter-spacing: var(--pre-body-04-letter-spacing);
  line-height: var(--pre-body-04-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

function getTextMetric(text, fontName, size) {

  const fontIdx = fontData.findIndex(font=>font.fontName === fontName);
  const fontFamily = fontData[fontIdx]["font-family"];
  const fontConfig = `${size}px ${fontFamily}`
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = fontConfig;
  const metrics = context.measureText(text);
  return metrics;
}

export const SectionPlayer = (): JSX.Element => {

  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [ended, setEnded] = useState(false);

  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [thumbs, setThumbs] = useState<any[]>([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isMuted, setIsMuted] = useState(false); 

  const [isPlaying, setIsPlaying] = useState(false);

  const editorContext = useContext(EditorContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext= useContext(EditorPageContext);
  const shopInfoContext = useContext(ShopInfoContext);
  const panelContext = useContext(PanelContext);
  const {textRendered, storedTextList} = useContext(PanelContext)
  /// 미리보기 페이지 컨텍스트
  let outputUrl = editorContext.outputVideoUrl;
  /// 출력 영상 URL : 출력 영상의 파일 Blob URL
  const outputUrlPresigned = editorContext.outputPresignedUrl;
  /// 출력 영상 presigned url : AWS S3에 업로드된 출력 영상 파일의 presigned url

  const videoPlayerRef =  useRef(null);
  const bgmRef = useRef(null);
  const ttsRef = useRef(null);

  const rafRef = useRef<number>()

  function formatMilliseconds(ms) {
    // 초, 분, 시간 계산
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    // 두 자리 형식으로 변환 (padStart로 0 채우기)
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

  const handleLoadMetaData = async (e:any)=>{
    const element = videoPlayerRef.current as HTMLVideoElement;
    setVideoDuration(element.duration);

    setIsVideoLoaded(true);
  }

  const [sceneTimeList, setSceneTimeList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  
  
  const sceneTimeListRef = useRef(sceneTimeList)
  
  const [grabSlider, setGrabSlider] = useState(false)
  
  const prevTimeRef = useRef(0);
  const playerTimeRef = useRef(0);

  const animationFrameId = useRef(null)
  const lastUpdateRef = useRef(0)
    
  const [bgVideos, setBgVideos] = useState([]);
  const bgVideoRefs=useRef([]);
  const [textVideos, setTextVideos] = useState([]);
  const textVideosRef = useRef([])

  const {isDragRect, setIsDragRect} = useContext(PanelContext);
  const {textIndex, setTextIndex} = useContext(PanelContext);
  const [dragStartPos, setDragStartPos] = useState({x:0, y:0});
  const [previewRatio, setPreviewRatio] = useState(1);
  
  const dragStartPosRef = useRef({x:0,y:0})
  const textRectListRef = useRef([])
  
  const rectWrapperRef = useRef(null);

  const [bgmUrl, setBgmUrl] = useState(undefined);


  useEffect(()=>{
    if(currentIndex!==undefined){
      textRectListRef.current = JSON.parse(JSON.stringify(editorPageContext.textRectList[currentIndex]));      
    }
  },[editorPageContext.textRectList, currentIndex])

  function getCurrentSceneTime(){
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
    if(previewEl){
      const playerTime = previewEl.currentTime
      let targetTime=0
      if(currentIndex===0){targetTime=playerTime}
      else{ targetTime = sceneTimeList[currentIndex-1] - playerTime;}
      return targetTime
    }else{
      return 0;
    }
  }

  useEffect(()=>{
    setList()
  },[scenarioContext.finalScenario])

  async function setList(){
    const newList=[]
    const newBgList = []

    for(let i=0; i<scenarioContext.finalScenario.scenes.length; i++){
      const scene = scenarioContext.finalScenario.scenes[i];

      if(newList.length===0){
        newList.push(scene.time);
      }else{
        newList.push(newList[newList.length-1] + scene.time);
      }
      const layoutPath = scene.layoutList[0].path;
      if(layoutPath==="search"){
        const name = shopInfoContext.shopName
        const charCode = name.charCodeAt(name.length - 1);          
        const consonantCode = (charCode - 44032) % 28;
        let searchBg = `./searchBarW_U3.png`
        const nameLength=name.length
        if(consonantCode === 0){ //를
          if(nameLength<=3){searchBg = `./searchBarW1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW4.png`}
          else {searchBg = `./searchBarW5.png`}
        }else{//을
          if(nameLength<=3){searchBg = `./searchBarW_U1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW_U2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW_U3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW_U4.png`}
          else {searchBg = `./searchBarW_U5.png`}
        }
        newBgList.push(searchBg)
      }else{
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: layoutPath.includes("i2v_client")? process.env.REACT_APP_OUTPUT_BUCKET_NAME :process.env.REACT_APP_INPUT_BUCKET_NAME,
          key : layoutPath
        })
        if(response.result===0){
          newBgList.push(response.presignedUrl)
        }else{
          newBgList.push("")
        }
      }      
    }
    // console.log("time array :: ", newList)
    setSceneTimeList(newList);
  }

  useEffect(()=>{
    /// URL리스트가 변경될 경우 요소 재생성
    // console.log("scene url changed")
    bgVideoRefs.current=[];
    const newList = editorPageContext.sceneVideoUrlList.map((url,index)=>{
      return <PreviewVideoElement
        key={`scene-bg${index}`}
        id={`preview-bg-video${index}`}
        preload=""
        muted
        src={url}
        ref={(el)=>(bgVideoRefs.current[index]=el)}
        style={{visibility: index===currentIndex? "visible":"hidden"}}
      />
    })
    setBgVideos(newList)
  },[editorPageContext.sceneVideoUrlList])

  useEffect(()=>{

    if(bgVideoRefs.current.length!==0){
      bgVideoRefs.current[currentIndex].currentTime = getCurrentSceneTime();
    }
  },[bgVideoRefs])

  useEffect(()=>{
    const newList = [];
    textVideosRef.current=[]
    editorPageContext.textVideoUrlList.forEach((sceneText, sceneIndex)=>{
      newList.push([])
      textVideosRef.current[sceneIndex] = []
      sceneText.forEach((url, index)=>{
        newList[sceneIndex].push(
          <PreviewVideoElement
            key={`scene${sceneIndex}-text${index}`}
            id={`scene${sceneIndex}-text${index}`}
            preload=""
            muted
            src={url}
            ref={(el)=>( textVideosRef.current[sceneIndex][index] = el )}
            style={{visibility: sceneIndex===currentIndex? "visible":"hidden"}}
          />
        );
      })
    })
    setTextVideos(newList)
  },[editorPageContext.textVideoUrlList])
 
  // useEffect(()=>{
  //   console.log("textVideosRef ::: ", textVideosRef.current)
  //   if(textVideosRef.current.length!==0){
  //     textVideosRef.current[currentIndex].forEach((textVideo, index)=>{
  //       if(index == textIndex){
  //         textVideo.style.top = '0px';
  //         textVideo.style.left = '0px'
  //       }
  //       textVideo.currentTime=getCurrentSceneTime()
  //     })
  //   }
  // },[textVideosRef])

  useEffect(()=>{
    if(textRendered){
      if(textVideosRef.current.length!==0){
        textVideosRef.current[editorPageContext.selectIndex].forEach((textVideo, index)=>{
          if(index == textIndex){
            textVideo.style.top = 'calc(50%)';
            textVideo.style.left = 'calc(50%)'
          }
          textVideo.currentTime=getCurrentSceneTime()
        })
      }
    }
  },[editorPageContext.textVideoUrlList, textVideosRef, textVideos, textRendered])

  useEffect(()=>{
    const ttsAudioEl = ttsRef.current as HTMLAudioElement
    if(ttsAudioEl){
      ttsAudioEl.src = editorPageContext.ntrUrlList[currentIndex];
      ttsAudioEl.load()

      const currTime = (currentTime||0)
      const sceneTime = sceneTimeList[currentIndex] || 0
      const firstSceneTime = sceneTimeList[0] || 0
      ttsAudioEl.currentTime = currTime - sceneTime + firstSceneTime
    }
  },[editorPageContext.ntrUrlList])



  useEffect(()=>{
    sceneTimeListRef.current = sceneTimeList
  },[sceneTimeList])

  function findCurrentIndex(now){
    // now 시간이 몇번째 장면인지 반환
    if(now===0){
      return 0
    }else if( now >= sceneTimeListRef.current[sceneTimeListRef.current.length-1]){
      return sceneTimeListRef.current.length-1
    }else{
      const idx = sceneTimeListRef.current.findIndex(time=>time>now);
      return idx
    }
  }


  
  const handleMouseDownSlider = () => {
    setGrabSlider(true)
  }
  const handleMouseUpSlider = () => {
    setGrabSlider(false)
  }

  const handleInputRangeChange = (event:any) =>{
    if(grabSlider){
      const time = event.target.value;
      const element = videoPlayerRef.current as HTMLVideoElement
      if(element) {
        element.currentTime = time;
      }
      const bgmAudioEl = bgmRef.current
      if(bgmAudioEl){
        bgmAudioEl.currentTime = Math.min(time,bgmAudioEl.duration);
      }
    }
  }

  useEffect(()=>{
    const element = videoPlayerRef.current as HTMLVideoElement
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl && element){
      if(editorPageContext.ntrUrlList[currentIndex]){
        ttsAudioEl.src= editorPageContext.ntrUrlList[currentIndex]||""
        ttsAudioEl.volume = editorContext.ttsVolumeList[currentIndex]/100
        if(!element.paused){
          ttsAudioEl.play()
        }
      }else{
        ttsAudioEl.pause()
        ttsAudioEl.src=undefined
      }
    }
  },[currentIndex])

  const handleStop = () => {
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      element.pause();
      element.currentTime=0;
    }
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      try{
        bgmAudioEl.pause();
        bgmAudioEl.currentTime =0;
      }catch(err){
        // console.error("bgm audio pause error ", err)
      }
    }
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){
      try{
        ttsAudioEl.pause()
        ttsAudioEl.currentTime = 0;
      }catch(err){
        // console.error("tts audio pause error ", err)
      }
    }
    Object.values(bgVideoRefs.current).forEach((video,index)=>{
      if(video){
        const videoEl = video as HTMLVideoElement
        if(index===0){
          videoEl.style.visibility="visible"
        }else{
          videoEl.style.visibility="hidden"
        }
      }
    })

    textVideosRef.current.forEach((sceneTexts, sceneIndex)=>{
      sceneTexts.forEach((video, textIndex)=>{
        if(video){
          const videoEl = video as HTMLVideoElement
          if(sceneIndex===0){
            videoEl.style.visibility="visible"
          }else{
            videoEl.style.visibility="hidden"
          }
        }
      })
    })
    
    // textRectListRef.current = JSON.parse(JSON.stringify(editorPageContext.textRectList[0])); 
    const bgId = `preview-bg-video0`;
    const bgEl = document.getElementById(bgId) as HTMLVideoElement;
    bgEl.currentTime=0;

    setCurrentIndex(0);
    setCurrentTime(0);
    setIsPlaying(prev=>false)//
  }

  const handlePlay = () => {
    // console.log('player handlePlay');
    if(ended){
      handleStop();
      setEnded(false);
    }
    setIsPlaying(prev=>true)//
  }

  useEffect(()=>{
    if(isPlaying){
      const element = videoPlayerRef.current as HTMLVideoElement
      if( element ) {
        element.play();
        // rafRef.current = requestAnimationFrame(updateProgress)
      }
  
      const bgmAudioEl = bgmRef.current
      if(bgmAudioEl){
        bgmAudioEl.volume = editorContext.bgmVolume/100
        bgmAudioEl.play();
      }
  
      const ttsAudioEl = ttsRef.current as HTMLAudioElement
      if(ttsAudioEl){
        // console.log('tts src : ', ttsAudioEl.src);
        try{
          if(ttsAudioEl.src && !ttsAudioEl.src.includes("/undefined")){
            ttsAudioEl.play()
          }
        }catch(err){
          // console.error("tts play error ::: ", err)
        }
      }
    }else{
      const element = videoPlayerRef.current as HTMLVideoElement
      if( element ) {
        element.pause();
        if(rafRef.current){
          // cancelAnimationFrame(rafRef.current)
        }
      }
      const bgmAudioEl = bgmRef.current
      if(bgmAudioEl){
        bgmAudioEl.pause();
      }

      const ttsAudioEl = ttsRef.current as HTMLAudioElement
      if(ttsAudioEl){
        try{
          if (ttsAudioEl.readyState < 3) {
            // 로드 중 상태
            ttsAudioEl.addEventListener('canplaythrough', pauseTTS, { once: true });
          } else {
            // 이미 로드 완료 상태
            pauseTTS()
          }
          ttsAudioEl.removeEventListener('canplaythrough',pauseTTS);
        }catch(err){
          console.error(err);
        }
      }
    }
  },[isPlaying])

  useEffect(()=>{
    if(isPlaying){

      const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
      let sceneTime = 0
      if(previewEl){
        let time = sceneTimeList[currentIndex] - previewEl.currentTime
        if(currentIndex===0){ time = previewEl.currentTime};
        sceneTime = time;       
        // // console.log("current player time :: ", time);
        // const currentTimeIndex = findCurrentIndex(time);
      }

      const bgVideos = Object.values(bgVideoRefs.current || {});
      bgVideos.forEach((video, index) => {
        const videoEl = video as HTMLVideoElement;
        if (!videoEl) return;
    
        if (index === currentIndex) {
          videoEl.style.visibility = "visible";
          videoEl.currentTime= sceneTime;
          videoEl.onseeked = () => {
            videoEl.play().catch((err) =>{
              console.error(`Failed to play video:`, err)
              videoEl.onseeked = () => {};

            });
            videoEl.onseeked = () => {};
          }
          videoEl.play().catch((err) =>
            console.error(`Failed to play video :`, err)
          );
        } else if (videoEl.style.visibility !== "hidden") {
          videoEl.style.visibility = "hidden";
          videoEl.pause();
        }
      });

      bgVideoRefs.current.forEach((video, index)=>{
        if(video){
          if (index === currentIndex) {
            video.style.visibility = "visible";
            video.currentTime= getCurrentSceneTime();
            video.onseeked = ()=>{
              video.play();
              video.onseeked=()=>{}
            }
          } else if (video.style.visibility !== "hidden") {
            video.style.visibility = "hidden";
            video.pause();
          }
        }
      })

      textVideosRef.current.forEach((sceneTexts, sceneIndex)=>{
        sceneTexts.forEach((video, textIndex)=>{
          const videoEl = video as HTMLVideoElement
          if(videoEl){
            if(sceneIndex===currentIndex){
              videoEl.style.visibility="visible"
              videoEl.currentTime= getCurrentSceneTime();
              videoEl.onseeked = ()=>{
                videoEl.play();
                videoEl.onseeked=()=>{}
              }
            }else{
              videoEl.style.visibility="hidden"
              videoEl.pause();
            }
          }
        })
      })

    }else{
      bgVideoRefs.current.forEach((video,index)=>{
        if(video){
          const videoEl = video as HTMLVideoElement
          videoEl.pause();
        }
      })

      textVideosRef.current.forEach((sceneTexts, sceneIndex)=>{
        sceneTexts.forEach((video, textIndex)=>{
          const videoEl = video as HTMLVideoElement
          if(videoEl){
            videoEl.pause();
          }
        })
      })
    }
  },[isPlaying, currentIndex])

  function pauseTTS(){
    const ttsAudioEl = ttsRef.current as HTMLAudioElement
    if(ttsAudioEl){
      ttsAudioEl.pause();
    }
  }

  const handlePause = () => {
    // console.log('player handlePause');
    setIsPlaying(prev=>false)//
  }

  const handleVideoEnd=()=>{
    setIsPlaying(prev=>false)//
    setEnded(true);
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      bgmAudioEl.pause();
    }
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){
      ttsAudioEl.pause()
    }
   
  }

  const handleBackward = () => {
    // console.log('player handleBackward');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      let seekTime = element.currentTime - 5;
      if(seekTime < 0) seekTime = 0;
      element.currentTime = seekTime;
      const seekedIndex = findCurrentIndex(seekTime);
      // setCurrentTime(seekTime);
      // setCurrentIndex(seekedIndex)
    }
  }

  const handleForward = () => {
    // console.log('player handleForward');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      let seekTime = element.currentTime + 5;
      if(seekTime > videoDuration) seekTime = videoDuration;
      element.currentTime = seekTime;
      const seekedIndex = findCurrentIndex(seekTime);
      // setCurrentTime(seekTime);
      // setCurrentIndex(seekedIndex)
    }
  }

  const handleSound = () => {
    // console.log('player handleSound');
    setIsMuted(!isMuted);
  }

  const handleFocus = () => {
    // console.log('player handleFocus');
  }

  useEffect(()=>{
    if(editorPageContext.bgmUrlList){
      if(scenarioContext.selectedBgmIndex!==undefined){
        setBgmUrl(editorPageContext.bgmUrlList[scenarioContext.selectedBgmIndex])
      }
    }
  },[scenarioContext.selectedBgmIndex])
  




  function withinDelta(value, target, delta){
    if(Math.abs(target - value) < delta){
      return true
    }else{
      return false
    }
  }

  function clipValue(val, min, max){
    return Math.min(Math.max(val, min), max);
  }

  useEffect(()=>{
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
    if(previewEl){
      const time = previewEl.currentTime
      // console.log("current player time :: ", time);
      const currentTimeIndex = findCurrentIndex(time);
      setCurrentIndex(currentTimeIndex);    
    }
  },[currentTime])



  useEffect(()=>{
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement

    const updateTime = () => {
      if (previewEl) {
        const now = performance.now();
        if( now - lastUpdateRef.current > 50){
          setCurrentTime(previewEl.currentTime);          
          lastUpdateRef.current = now;
        }
        playerTimeRef.current = previewEl.currentTime;
        animationFrameId.current = requestAnimationFrame(updateTime);
      }
    };

    const handlePlayPlayer = () => {
      animationFrameId.current = requestAnimationFrame(updateTime);
      setIsPlaying(true);
    };

    const handlePausePlayer = () => {
      // cancelAnimationFrame(animationFrameId.current);
      setIsPlaying(false)
    };

    const handleTimeUpdatePlayer = () => {
      setCurrentTime(previewEl.currentTime);
    };
    const handleTimeSeekingPlayer = (event) => {
      const videoEl = event.target;
      // console.log("Preview seeking")
      setCurrentTime(videoEl.currentTime)
    }
    const handleTimeSeekedPlayer = (event) => {
      // console.log("Preview seeked")
      const videoEl = event.target;

      const time = videoEl.currentTime;
      const seekedIndex = findCurrentIndex(time);

      // console.log(`seeking index :: ${seekedIndex}`)

      const sceneTimeStamp = sceneTimeList[seekedIndex-1] || 0
      const firstSceneTime = sceneTimeList[0] || 0
      
      let sceneTime = time - sceneTimeStamp ;
      if(seekedIndex===0){
        sceneTime=time
      }
      
      bgVideoRefs.current.forEach((video, index)=>{
        const videoEl = video as HTMLVideoElement;
        if (!videoEl) return;
    
        if (index == seekedIndex) {
          // console.log(`=========== set visible ${index}`)
          videoEl.style.visibility = "visible";
          videoEl.currentTime = sceneTime;
          if(isPlaying){
            videoEl.onseeked=()=>{
              videoEl.play().catch((err) =>{
                console.error(`Failed to play video :`, err)
                videoEl.onseeked=()=>{}  
              });
              videoEl.onseeked=()=>{}
            }
            videoEl.play().catch((err) =>
              console.error(`Failed to play video at index ${index}:`, err)
            );
          }
        } else if (videoEl.style.visibility !== "hidden") {
          videoEl.style.visibility = "hidden";
          if(isPlaying){
            videoEl.pause();
          }
        }
      })

      textVideosRef.current.forEach((sceneTexts, sceneIndex)=>{
        sceneTexts.forEach((video, textIndex)=>{
          if(video){
            if (sceneIndex == seekedIndex) {
              video.style.visibility = "visible";
              video.currentTime = sceneTime;
              if(isPlaying){
                video.onseeked=()=>{
                  video.play().catch((err) => {
                    console.error(`Failed to play video :`, err)
                    video.onseeked=()=>{}
                  });
                  video.onseeked=()=>{}
                }
                video.play().catch((err) =>
                  console.error(`Failed to play video at index ${sceneIndex}_${textIndex}:`, err)
                );
              }
            }else if(video.style.visibility !== "hidden") {
              video.style.visibility = "hidden";
            }
          }
        })
      })

      setEnded(false);
      setCurrentIndex(seekedIndex);
      setCurrentTime(videoEl.currentTime)
    }
    
    const handleEndPlayer = () => {
      // console.log("Player ended")
      setEnded(true);
    }

    if (previewEl) {
      previewEl.addEventListener("play", handlePlayPlayer);
      previewEl.addEventListener("pause", handlePausePlayer);
      // previewEl.addEventListener("timeupdate", handleTimeUpdatePlayer);
      previewEl.addEventListener("seeking", handleTimeSeekingPlayer);
      previewEl.addEventListener("seeked", handleTimeSeekedPlayer);
      previewEl.addEventListener("ended", handleEndPlayer);
    }

    return () => {
      if (previewEl) {
        previewEl.removeEventListener("play", handlePlayPlayer);
        previewEl.removeEventListener("pause", handlePausePlayer);
        // previewEl.removeEventListener("timeupdate", handleTimeUpdatePlayer);
        previewEl.removeEventListener("seeking", handleTimeSeekingPlayer);
        previewEl.removeEventListener("seeked", handleTimeSeekedPlayer);
        previewEl.removeEventListener("ended", handleEndPlayer);
      }
      cancelAnimationFrame(animationFrameId.current);
    };
  },[])

 

  const handleRectMouseDown = (event, index) => {
    editorPageContext.setSelectIndex(currentIndex)
    editorPageContext.setSelectType("text")
    setDragStartPos({x:event.clientX, y:event.clientY})
    setTextIndex(index);
    // setRectIndex(index)
    setIsDragRect(true)
    dragStartPosRef.current={x:event.clientX, y:event.clientY}
  }



  useEffect(()=>{
    window.addEventListener("resize", setRatio)
    return ()=>{
      window.removeEventListener("resize", setRatio) 
    }
  },[])

  function setRatio(){
    const el = rectWrapperRef.current as HTMLElement
    if(!el){return;}
    const elRect= el.getBoundingClientRect();
    const ratioH = (elRect.height/1080);
    setPreviewRatio(ratioH);
  }

  useEffect(()=>{
    drawRects();
  },[previewRatio])
  
  function drawRects(){
    try{
      if(textVideosRef.current.length>0){
        textVideosRef.current[currentIndex].forEach((video, textIndex)=>{
          const id = `scene${currentIndex}-text${textIndex}`
          const targetEl = document.getElementById(id);
          if(targetEl){
            const posX = storedTextList[currentIndex][textIndex].posX
            const posY = storedTextList[currentIndex][textIndex].posY
  
            const top = (textRectListRef.current[textIndex].y)*previewRatio - posY*previewRatio;
            const left = (textRectListRef.current[textIndex].x)*previewRatio - posX*previewRatio;
    
            targetEl.style.top= `calc(${top}px + 50%)`
            targetEl.style.left= `calc(${left}px + 50%)`
          }
        })
      }
    }catch(err){console.error(err)}
  }

  useEffect(()=>{
    if(textVideos.length!==0){
      textVideosRef.current[currentIndex].forEach((video, textIndex)=>{
        const id = `scene${currentIndex}-text${textIndex}`
        const targetEl = document.getElementById(id);
        if(targetEl){
          let posX=0;
          let posY=0;
          posX = storedTextList[currentIndex][textIndex].posX
          posY = storedTextList[currentIndex][textIndex].posY
  
          const top = (textRectListRef.current[textIndex].y - posY)*previewRatio;
          const left = (textRectListRef.current[textIndex].x - posX)*previewRatio;
  
          targetEl.style.top= `calc(${top}px + 50%)`
          targetEl.style.left= `calc(${left}px + 50%)`
        }
      })
    }
  },[storedTextList])

  const handleMouseMove = (e)=>{
    e.preventDefault();
    e.stopPropagation();
    const el = rectWrapperRef.current as HTMLElement

    if(isDragRect && el){
      const elRect= el.getBoundingClientRect();

      textRectListRef.current[textIndex].x += (e.clientX - dragStartPosRef.current.x) * (1920/elRect.width)
      textRectListRef.current[textIndex].y += (e.clientY - dragStartPosRef.current.y) * (1080/elRect.height)

      dragStartPosRef.current={x:e.clientX, y:e.clientY}


      const targetVideoId = `scene${currentIndex}-text${textIndex}`;
      const targetEl = document.getElementById(targetVideoId);
      if(targetEl){
        let posX=0;
        let posY=0;
        posX = storedTextList[currentIndex][textIndex].posX
        posY = storedTextList[currentIndex][textIndex].posY
        
        
        const ratioH = (elRect.height/1080);
        const ratioW = (elRect.width/1920);

        const top = (textRectListRef.current[textIndex].y)*ratioH - posY*ratioH;
        const left = (textRectListRef.current[textIndex].x)*ratioW - posX*ratioW;

        targetEl.style.top= `calc(${top}px + 50%)`
        targetEl.style.left= `calc(${left}px + 50%)`
      }
      
      const targetRectId = `scene${currentIndex}-text${textIndex}-rect`;
      const rectEl = document.getElementById(targetRectId) as HTMLElement;
      if(rectEl){

        const playerEl = rectWrapperRef.current as HTMLElement;
        let W = 1920;
        let H = 1080;
        if(playerEl){
          const rect = playerEl.getBoundingClientRect();
          W = rect.width;
          H = rect.height;
          // console.log(`W/H : ${W}/${H}`)
          const item = textRectListRef.current[textIndex];
          rectEl.style.top = `${H*item.y/1080 - 8}px`;
          rectEl.style.left = `${W*item.x/1920 - 8}px`;
          rectEl.style.width =`${W*item.w/1920 + 16}px`;
          rectEl.style.height =`${H*item.h/1080 + 16}px`;
        }
      }
    }
  }

  useEffect(()=>{
    setIsDragRect(false)
  },[])

  useEffect(()=>{
    textRectListRef.current.forEach((rect, index)=>{
      const id = `scene${currentIndex}-text${index}-rect`
      const rectEl = document.getElementById(id);
      if(rectEl){
        if(isPlaying){
          rectEl.style.display="none"
        }else{
          rectEl.style.display="unset"
        }
      }        
    })
  },[isPlaying])

  
  useEffect(()=>{
    if(!isDragRect){
      // let temp = JSON.parse(JSON.stringify(editorPageContext.textRectList));
      // temp[currentIndex] = textRectListRef.current;
      // editorPageContext.setTextRectList(temp);

      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      
      textRectListRef.current.forEach((rect, textIndex)=>{
        finalScenario.scenes[currentIndex].textList[textIndex].posX = rect.x;
        finalScenario.scenes[currentIndex].textList[textIndex].posY = rect.y;
      })
      scenarioContext.setFinalScenario(finalScenario);
    }
  },[isDragRect])


  return (
    <SectionPlayerContainer>
      <PlayerBody>
        <PreviewWrapper>
          <PreviewStyle id={`preview-player-realtime`} className={"preview-player-realtime"} >
            <audio preload="" style={{position:"absolute"}} ref={bgmRef} onError={(err)=>{console.error("bgm error ", err)}} src={bgmUrl} muted={isMuted}/>
            <audio preload="" style={{position:"absolute"}} ref={ttsRef} onError={(err)=>{console.error("tts error ", err)}} muted={isMuted}/>

            {bgVideos.map(video=>video)}
            
            <TextElementsWrapper
              ref={rectWrapperRef}
              // draggable={false}
              onMouseLeave={()=>{setIsDragRect(false)}}
              onMouseUp={()=>{setIsDragRect(false)}}
              onDrag={(e)=>{e.preventDefault()}}
              onMouseMove={handleMouseMove}
            >
              <TextVideWrapper 
                // draggable={false}
                // onMouseLeave={(e)=>{e.preventDefault();e.stopPropagation()}}
                onMouseLeave={(e)=>{}}
                onDrag={(e)=>{e.preventDefault();e.stopPropagation()}}
                // onMouseMove={(e)=>{e.preventDefault();e.stopPropagation()}}
              >
                {textVideos.map(sceneTexts=>sceneTexts.map(video=>video))}              
              </TextVideWrapper>
              <TextRectWrapper
                // draggable={false}
                onDrag={(e)=>{e.preventDefault();e.stopPropagation()}}
                onMouseLeave={(e)=>{e.preventDefault();e.stopPropagation();}}
                // onMouseMove={(e)=>{e.preventDefault()}}
              >
                {textRectListRef.current.map((item, index)=>{
                  const playerEl = rectWrapperRef.current as HTMLElement;
                  let W = 1920;
                  let H = 1080;
                  if(playerEl){
                    const rect = playerEl.getBoundingClientRect();
                    W = rect.width;
                    H = rect.height;
                    // console.log(`W/H : ${W}/${H}`)
                  }
                  return(
                    <TextRect
                      draggable={false}
                      className={(isDragRect&&textIndex===index)?"active":""}
                      id={`scene${currentIndex}-text${index}-rect`}
                      style={{
                        position:"absolute",
                        transformOrigin: `top left`,
                        transform:`rotate(${item.rotate}deg)`,
                        top: `${H*item.y/1080 - 8}px`,
                        left:`${W*item.x/1920 - 8}px`,
                        width:`${W*item.w/1920 + 16}px`,
                        height:`${H*item.h/1080 + 16}px`,
                        zIndex: 30,
                      }}
                      onMouseDown={(e)=>handleRectMouseDown(e,index)}
                      onMouseLeave={(e)=>{e.stopPropagation();e.preventDefault();}}
                      // onMouseUp={()=>{setIsDragRect(false)}}
                    />
                  )
                })}
              </TextRectWrapper>
              
            </TextElementsWrapper>
          </PreviewStyle>
        </PreviewWrapper>
        <PlayerStyle ref = {videoPlayerRef}
          className={"preview-video-player"}
          onContextMenu={(e)=>{e.preventDefault()}}
          // src={editorContext.outputVideoUrl}
          src={editorPageContext.blankVideoUrl}
          onLoadedMetadata={handleLoadMetaData}
          onEnded={handleVideoEnd}
          muted
          style={{opacity:0, position:"absolute"}}
        ></PlayerStyle>
        <VideoSlider
          handleMouseDownSlider={handleMouseDownSlider}
          handleMouseUpSlider={handleMouseUpSlider}
          videoDuration={videoDuration}
          currentTime={currentTime}
          // handleTimeChange={handleTimeChange}
          handleTimeChange={handleInputRangeChange}
          videoRef={videoPlayerRef}
        />
        <Box display="flex" sx={{ width: '100%', paddingX: '4px'}} justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleForward}>
              <ControlForwardIcon width={24} height={24} />
            </IconButton>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleBackward}>
              <ControlBackwardIcon width={24} height={24} />
            </IconButton>
            {
              !isPlaying?
                <IconButton size="small" sx={{
                  color: '#17191C', // 기본 색상
                  fill: '#17191C',
                  '&:hover': {
                    color: '#3617CE', // 호버 시 색상
                    fill: '#3617CE',
                  },
                }} onClick={handlePlay}>
                  <ControlPlayIcon width={24} height={24} />
                </IconButton>
              :
                <IconButton size="small" sx={{
                  color: '#17191C', // 기본 색상
                  fill: '#17191C',
                  '&:hover': {
                    color: '#3617CE', // 호버 시 색상
                    fill: '#3617CE',
                  },
                }} onClick={handlePause}>
                  <ControlPauseIcon width={24} height={24} />                  
                </IconButton>
            }
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleStop}>
              <ControlStopIcon width={24} height={24} />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleSound}>
              {!isMuted && <ControlSoundOnIcon width={24} height={24} />}
              {isMuted && <ControlSoundOffIcon width={24} height={24} />}
            </IconButton >
            {/* <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleFocus}>
              <ControlFocusIcon width={24} height={24} />
            </IconButton> */}
          </Box>
        </Box>
      </PlayerBody>
    </SectionPlayerContainer>
  );
};

function VideoSlider(props:any){

  function formatMilliseconds(ms) {
    // 초, 분, 시간 계산
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    // 두 자리 형식으로 변환 (padStart로 0 채우기)
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const inputRef = useRef();

  const [ratio, setRatio] = useState(0)
  useEffect(()=>{

    setRatio(props.currentTime/props.videoDuration*100);
  },[props.currentTime, props.videoDuration])

  return (
    <Stack gap={2} direction="row" sx={{ alignItems:"center", width: '100%', marginTop: '4px', marginLeft: '16px', paddingRight: '20px' }}>
      {/* <Slider
        defaultValue={0}
        onMouseDown={props.handleMouseDownSlider}
        onMouseUp={props.handleMouseUpSlider}
        min={0}
        max={props.videoDuration}
        step={0.1}
        value={props.currentTime}
        onChange={props.handleTimeChange}
      /> */}
      
      <div style={{width:"100%", position:"relative"}}>
        <StyledSlider
          ref={inputRef}
          type="range"
          id="time-slider"
          min={0}
          max={props.videoDuration}
          step={0.1}
          value={props.currentTime}
          onChange={props.handleTimeChange}
          onMouseDown={props.handleMouseDownSlider}
          onMouseUp={props.handleMouseUpSlider}
          style={{width:"100%"}}
        />
        <ProgressBar style={{width:`calc(4px + (${ratio} * (100% - 8px - 4px) / 100))`, height:"4px", left:"6px", top:"calc(50% + 2px)", borderRadius:"4px"}}/>
      </div>
      <Box display="flex" sx={{ width: '220px' }} justifyContent="center" gap={1} alignItems="center">
        <TextWrapper21>{formatMilliseconds(props.currentTime*1000)}</TextWrapper21>
        <TextWrapper21>/</TextWrapper21>
        <TextWrapper21>{formatMilliseconds(props.videoDuration*1000)}</TextWrapper21>
      </Box>
    </Stack>
  )
}


const TextElementsWrapper = styled.div`
 position: absolute;
  // border: 1px solid blue;
  overflow: hidden;
  // background-color: rgba(255,0,0,0.5);
  z-index:9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  // height:100%;
  max-height:100%;
  max-width:100%;
  aspect-ratio: 16/9;
  // pointer-events: none;
`

const TextVideWrapper = styled.div`
  position: absolute;
  // border: 1px solid blue;
  overflow: hidden;
  background-color: rgba(0,0,0,0);
  z-index:8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  // height:100%;
  max-height:100%;
  max-width:100%;
  aspect-ratio: 16/9;
  pointer-events: none;
`

const TextRectWrapper = styled.div`
  position: absolute;
  overflow: hidden;
  // background-color: rgba(0,255,0, 0.3);
  z-index:8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  // height:100%;
  max-height:100%;
  max-width:100%;
  aspect-ratio: 16/9;
  mix-blend-mode: overlay;
`

const TextRect = styled.div`
  border: none;
  background-color: transparent;
  pointer-events: all;
  user-select: none;
  
  &:hover{
    border: 2px solid;
    border-color: ${props=>props.theme.colors["border-active"]};
    background-color: rgba(0,0,0,0.5);
    mix-blend-mode: overlay;
    cursor: grab;
  };

  &.active{
    border: 2px solid;
    border-color: ${props=>props.theme.colors["border-active"]};
    background-color: rgba(0,0,0,0.5);
    mix-blend-mode: overlay;
    cursor: grabbing;
  }
`


const ProgressBar = styled.div`
  height:4px;
  background-color: ${props=>props.theme.colors["border-active"]};
  position:absolute;

`

const SliderInput = styled.input`
  width:100%;
  height: 4px;
  -webkit-appearance: none; /* Remove default styling */
  background: linear-gradient(to right, #FFE283 0%, #FFE283 50%, #ececec 50%, #ececec 100%);
  border-radius: ${props=>props.theme.radius["radius-full"]};
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${props=>props.theme.colors["border-active"]};
    cursor: pointer;
  }
`

interface InputProps {
  background: string; // 사용자 정의 속성
}
const StyledSlider = styled.input`
  width: 100%;
  height: 4px;
  -webkit-appearance: none; /* Remove default styling */
  border-radius: ${props=>props.theme.radius["radius-full"]};
  background-color: ${props=>props.theme.colors["background-secondary"]};

  
  &::-webkit-slider-runnable-track{
    outline: none;
  };

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${props=>props.theme.colors["border-active"]};
    cursor: pointer;
  }
`;