import React from "react";
import styled from "styled-components";
import { VideoTabContent } from "./sections/VideoTabContent"; 
import { SceneTransitionTabContent } from "./sections/SceneTransitionTabContent";
import { ScreenEffectTabContent } from "./sections/ScreenEffectTabContent";
import { AIStyleTabContent } from "./sections/AIStyleTabContent";

const StyledTabContainerNode = styled.div`
  position:relative;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--tokens-spacing-07-duplicate);
  overflow-y: scroll;
  padding: var(--tokens-spacing-08-duplicate) 0px var(--tokens-spacing-08-duplicate) 0px;
  position: relative;
  width: 100%;
`;

export const VideoTabContainer = ({index}): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <VideoTabContent index={index}/>
    </StyledTabContainerNode>
  );
};

export const SceneTransitionTabContainer = ({index}): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <SceneTransitionTabContent index={index}/>
    </StyledTabContainerNode>
  );
};


export const ScreenEffectTabContainer = ({index}): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <ScreenEffectTabContent index={index}/>
    </StyledTabContainerNode>
  );
};


export const AIStyleTabContainer = ({index}): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <AIStyleTabContent index={index}/>
    </StyledTabContainerNode>
  );
};

