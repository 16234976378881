import React, { useEffect, useState, useRef, useContext } from "react";
import { Box, Typography, TextField, Button, InputBase } from "@mui/material";

import styled from "styled-components";
import { InputStepper } from "../../../../../../../Components/pages/Editor/InputStepper";

import { ReactComponent as MusicPlayIcon } from "../../../../../../../Assets/icon-editor/music-play.svg";
import { ReactComponent as MusicPauseIcon } from "../../../../../../../Assets/icon-editor/music-pause.svg";

import characterSample from "../../../../../../../Assets/CharacterAsset/character.svg";
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";

import ModalAddress from "../../../../../../../Components/pages/ShopInfo/ModalAddress";
import { EditorPageContext } from "../../../../../editorPageContext";

import { useTranslation } from "react-i18next";

interface Props {
  name: string;
  address: string;
  duration: number;
  atmosphere: string;
  createDate: string;
  onCommand: any;
}

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const SimpleInput = styled(InputBase)(({ theme }) => ({
  width: '194px',
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: '#E2E4E7',
    fontSize: 12,
    flex: 1,
    height: '28px',
    padding: '0px 0px 4px 2px',
    margin: 0,
    // transition: theme.transitions.create([
    //   'border-color',
    //   'background-color',
    //   'box-shadow',
    // ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: 'blue',
    },
    // ...theme.applyStyles('dark', {
    //   backgroundColor: '#1A2027',
    //   borderColor: '#2D3843',
    // }),
  },
}));

export const InfoTabContent = ({ name, address, duration, atmosphere, createDate, onCommand }: Props): JSX.Element => {
  const {t} = useTranslation();
  const [isEdit, setIsEdit] = useState([false, false, false, false, false]);
  const [isShow, setIsShow] = useState([true, true, true, true, true]);
  const [nameVal, setNameVal] = useState(name);
  const [addressVal, setAddressVal] = useState(address);
  const [durationVal, setDurationVal] = useState(duration);
  const [atmosphereVal, setAtmosphereVal] = useState(atmosphere);
  const [createDateVal, setCreateDateVal] = useState(createDate);

  const shopInfoContext = useContext(ShopInfoContext);

  const editorPageContext = useContext(EditorPageContext);

  useEffect(()=>{
    setAddressVal(shopInfoContext.shopAddress);
  },[shopInfoContext.shopAddress])

  const handleEditState = (index: number, item: string, val: number | string) => {

    switch(index){
      case 0:
        shopInfoContext.setShopName(nameVal);
        break;
      case 1:
        break;
      case 2:
        break;
      case 3:
        break;
      case 4:
        break;
    }

    setIsEdit(prevState => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });

    setIsShow(prevState => {
      if (!isEdit[index]) {
        const newState = [false, false, false, false, false];
        newState[index] = true;
        return newState;
      } else {
        const newState = [true, true, true, true, true];
        return newState;
      }

    }); 

    onCommand(item, val);
  };

  const handleChangeDuration = (e) => {
    const { value } = e.target;

    console.log("duration change event ::: ", e)

    if (/^\d*$/.test(value)) {  // 정규식을 사용해 숫자만 허용
      setDurationVal(value);
    }
  };

  const handleChangeShopName = (e) => {
    const newName = e.currentTarget.value
    setNameVal(newName)
  }
  const handleShopNameKeyDown = (e) => {
    if(e.code==="Enter"){
      handleEditState(0, "name", nameVal);
    }
  }

  const handleChangeAddress = (newAddress) => {
    console.log('new address :: ', newAddress);
    setAddressVal(newAddress);
    shopInfoContext.setShopAddress(newAddress);
  }

  return (
    <StyledFrame>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <InfoItemFrame>
          <InfoItemNameTextWrapper>{t("pages-editor-main-infopanel.c1")}</InfoItemNameTextWrapper>
          { isEdit[0] ?
            <SimpleInput value={nameVal} onKeyDown={handleShopNameKeyDown} onChange={handleChangeShopName} />
            :
            <Typography variant="caption" sx={{ flex: 1, marginLeft: 0, paddingLeft: 0 }}>{name}</Typography>
          }
        </InfoItemFrame>
        {/* {isShow[0]&&
          <UnderlineButtonStyle onClick={()=>handleEditState(0, "name", nameVal)}>
            { isEdit[0] ? "저장" : "수정" }
          </UnderlineButtonStyle>
        } */}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <InfoItemFrame>
          <InfoItemNameTextWrapper>{t("pages-editor-main-infopanel.c2")}</InfoItemNameTextWrapper>
          {isEdit[1] ? 
            <SimpleInput value={addressVal} ></SimpleInput>
            :
            <Typography variant="caption" sx={{ flex: 1 }}>{addressVal}</Typography>
          }
        </InfoItemFrame>
        {/* {isShow[1]&&
          <UnderlineButtonStyle onClick={()=>handleEditState(1, "address", addressVal)}>
            { isEdit[1] ? "저장" : "수정" }
          </UnderlineButtonStyle>
        } */}
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <InfoItemFrame>
          <InfoItemNameTextWrapper>{t("pages-editor-main-infopanel.c3")}</InfoItemNameTextWrapper>
          {isEdit[2]?
            <SimpleInput value={durationVal} type="number" onChange={handleChangeDuration}></SimpleInput>
            :
            <Typography variant="caption" sx={{ flex: 1 }}>{duration}</Typography>
          }
        </InfoItemFrame>
        {/* {isShow[2]&&
          <UnderlineButtonStyle onClick={()=>handleEditState(2, "duration", durationVal)}>
            { isEdit[2] ? "저장" : "수정" }
          </UnderlineButtonStyle>
        } */}
      </Box>
      {/* <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <InfoItemFrame>
          <InfoItemNameTextWrapper>광고 분위기</InfoItemNameTextWrapper>
          {isEdit[3]?
            <SimpleInput value={atmosphereVal} onChange={(e)=> setAtmosphereVal(e.target.value)}/>
            :
            <Typography variant="caption" sx={{ flex: 1 }}>{atmosphere}</Typography>
          }
        </InfoItemFrame>
        {isShow[3]&&
          <UnderlineButtonStyle onClick={()=>handleEditState(3, "atmosphere", atmosphereVal)}>
            { isEdit[3] ? "저장" : "수정" }
          </UnderlineButtonStyle>
        }
      </Box> */}
      <Box display="flex" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <InfoItemFrame>
          <InfoItemNameTextWrapper>{t("pages-editor-main-infopanel.c4")}</InfoItemNameTextWrapper>
          {isEdit[4]?
            <SimpleInput value={createDateVal} onChange={(e)=> setCreateDateVal(e.target.value)}/>
            :
            <Typography variant="caption" sx={{ flex: 1 }}>{createDate}</Typography>
          }
        </InfoItemFrame>
        {/* {isShow[4]&&
          <UnderlineButtonStyle onClick={()=>handleEditState(4, "createDate", createDateVal)}>
            { isEdit[4] ? "저장" : "수정" }
          </UnderlineButtonStyle>
        } */}
      </Box>
      {
        isEdit[1]&&
        <ModalAddress show={isEdit[1]} onClose={()=>{handleEditState(1,"address",addressVal)}} setAddress={handleChangeAddress}/>
      }
    </StyledFrame>
  );
};

const InfoItemFrame = styled.div`
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  display:flex;
  align-items:center;
  
`
const InfoItemNameTextWrapper = styled.div`
  color:${(props)=>props.theme.colors["text-tertiary"]};
  ${(props)=>props.theme.typography["pre-caption-02"]};
  width:56px;
`
const UnderlineButtonStyle = styled.button`
  color:${(props)=>props.theme.colors["text-quaternary"]};
  ${(props)=>props.theme.typography["pre-caption-01"]};
  text-decoration: underline;
  text-underline-offset: 3px;
  
  border:none;
  background-color:transparent;
  padding:0px;

  cursor:pointer;
  width:21px;
  min-width:21px;
`