import React, {useContext, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import { Trans } from 'react-i18next';
import { EditorContext } from '../../../../Contexts/EditorContext';

export function TutorialTooltip(props){
  const [tutorialStep, setTutorialStep] = useState(0);
  const [tutorialDescription, setTutorialDescription] = useState<any>(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c1"} components={{span:<span></span>, br:<br/>}}/>)
  const tutoRef = useRef(null)
  const [direction, setDirection] = useState("right");

  const {setSeenEditorTutorial} = useContext(EditorContext)

  useEffect(()=>{
    setTutorialData()
  },[tutorialStep])

  async function setTutorialData(){
    let direct = "left"
    switch(tutorialStep){
      case 0:
        direct='left';
        setDirection("left")
        setTutorialDescription(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c1"} components={{span:<span></span>, br:<br/>}}/>)
        break;
      case 1:
        direct='up';
        setDirection("up")
        setTutorialDescription(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c2"} components={{span:<span></span>, br:<br/>}}/>)
        break;
      case 2:
        direct='down';
        setDirection("down")
        setTutorialDescription(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c3"} components={{span:<span></span>, br:<br/>}}/>)
        break;
      case 3:
        direct='down';
        setDirection("down")
        setTutorialDescription(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c4"} components={{span:<span></span>, br:<br/>}}/>)
        break;
      case 4:
        direct='up';
        setDirection("up")
        setTutorialDescription(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c5"} components={{span:<span></span>, br:<br/>}}/>)
        break;
      case 5:
        direct='up';
        setDirection("up")
        setTutorialDescription(<Trans i18nKey={"com-pages-editor-tutorialtooltip.c6"} components={{span:<span></span>, br:<br/>}}/>)
        break;
    }
    
  }
  useEffect(()=>{
    moveTooltip()
  },[tutorialStep, direction])

  function moveTooltip(){
    const anchorList = document.getElementsByClassName("tutorials");
    Array.from(anchorList).forEach((elem)=>{
      const el = elem as HTMLElement
      el.style.zIndex="unset"
      el.style.pointerEvents="unset"
    });
    const anchorTarget = document.getElementsByClassName(`tutorial-step-${tutorialStep+1}`)[0] as HTMLElement
    if(anchorTarget){
      anchorTarget.style.zIndex="9002";
      anchorTarget.style.pointerEvents="none";
    }

    const focusTarget = document.getElementsByClassName(`tutorial-focus-${tutorialStep+1}`)[0] as HTMLElement
    if(focusTarget){
      focusTarget.style.zIndex="9002";
      focusTarget.style.pointerEvents="none";
    }

    if(tutorialStep>=0){
      const rect= anchorTarget.getBoundingClientRect();
      let top = rect.y;
      let left = rect.x;

      const tutoEl = tutoRef.current as HTMLElement

      const arrowWrapperTop = document.getElementById("arrow-wrapper-top")
      const arrowWrapperBottom = document.getElementById("arrow-wrapper-bottom")

      const arrowWrapper = arrowWrapperTop? arrowWrapperTop: arrowWrapperBottom

      //20px
      if(tutoEl && arrowWrapper){
        // anchorTarget.appendChild(tutoEl);
        const tutoRect = tutoEl.getBoundingClientRect();

        switch(tutorialStep){
          case 0:
            top = rect.y + (rect.height - tutoRect.height)/2;
            left = rect.x + rect.width;
            arrowWrapper.style.justifyContent="center";
            break;
          case 1:
            top = rect.y + rect.height;
            left = rect.x + (rect.width - tutoRect.width)/2;
            arrowWrapper.style.justifyContent="center";
            break;
          case 2:
            top = rect.y - tutoRect.height;
            left = rect.x+240;
            arrowWrapper.style.justifyContent="center";
            break;
          case 3:
            top = rect.y - tutoRect.height;
            left = rect.x;
            arrowWrapper.style.justifyContent="flex-start";
            break;
          case 4:
            top = rect.y + rect.height;
            left = rect.x + (rect.width - tutoRect.width)/2;
            arrowWrapper.style.justifyContent="center";
            break;
          case 5:
            top = rect.y + rect.height;
            left = rect.x + (rect.width - tutoRect.width)/2;
            arrowWrapper.style.justifyContent="center";
            break;
        }
        console.log("setting anchor")

        if(left<40){
          left=40;
        }

        tutoEl.style.top= `${top}px`;
        tutoEl.style.left= `${left}px`;
      }
    }
    return true
  }

  const handleOnClick=()=>{
    if(tutorialStep === 5){  
      const anchorList = document.getElementsByClassName("tutorials");
      Array.from(anchorList).forEach((elem)=>{
        const el = elem as HTMLElement
        el.style.zIndex="unset"
        el.style.pointerEvents="unset"
      });
      setSeenEditorTutorial(true)
    }

    setTutorialStep(prev=>( (1+ prev)%6))

    if(props.onClick){
      // props.onClick();
    }
  }

  return(
    <TutorialWrapper direction={direction} ref={tutoRef}>
      {
        (direction==="left"||direction==="up")?
          <ArrowWrapper id={"arrow-wrapper-top"} direction={direction}>
          {direction==="left"?
            <ArrowLeft style={{marginLeft:"4px"}}/>
            :<ArrowUp style={{marginTop:"4px"}}/>
          }  
          </ArrowWrapper>
          :<></>
      }
      <TooltipContainer onClick={handleOnClick}>
        <StepWrapper>
          {tutorialStep+1}/6
        </StepWrapper>
        <TextWrapper>
          {tutorialDescription}
        </TextWrapper>
      </TooltipContainer>
      {
        (direction==="right"||direction==="down")?
        <ArrowWrapper id={"arrow-wrapper-bottom"} className={"arrow-wrapper"} direction={direction}>
          {direction==="right"?
            <ArrowLeft style={{transform:"scale(-1,1)", marginRight:"4px"}}/>
            :<ArrowUp style={{transform:"scale(1,-1)",marginBottom:"4px"}}/>}
        </ArrowWrapper>
        :<></>
      }
    </TutorialWrapper>
  )
}

function ArrowLeft(props){
  return(
    <svg width="12" height="27" viewBox="0 0 12 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>  
      <path id="Polygon 3" d="M1.25221 14.8566C0.545017 14.0904 0.545019 12.9096 1.25221 12.1434L12 0.5V26.5L1.25221 14.8566Z" fill="#2D3236"/>  
    </svg>
  )
}

function ArrowUp(props){
  return (
    <svg width="26" height="12" viewBox="0 0 26 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.6434 1.25221C12.4096 0.545017 13.5904 0.545019 14.3566 1.25221L26 12L0 12L11.6434 1.25221Z" fill="#2D3236"/>
    </svg>
  )
}

const TutorialWrapper = styled.div<{direction}>`
  position:fixed;
  top:0px;
  left:0px;
  z-index:9003;
  display: flex;
  align-items:center;
  justify-content:center;
  flex-direction: ${(props)=>(props.direction==="left"||props.direction==="right")?"row":"column"};
`

const ArrowWrapper = styled.div<{direction}>`
  display:flex;
  width: ${(props)=>{
    switch(props.direction){
      case "left":
      case "right":
        return "fit-content"
      case "up":
      case "down":
        return "100%"
      default:
        return "100%"
    }
  }};
  height: ${(props)=>{
    switch(props.direction){
      case "left":
      case "right":
        return "100%"
      case "up":
      case "down":
        return "fit-content"
      default:
        return "100%"
    }
  }};
  padding: ${(props)=>{
    switch(props.direction){
      case "left":
      case "right":
        return "0px"
      case "up":
      case "down":
        return "0px 20px"
      default:
        return "0px"
    }
  }};
  margin: 0px;
`

const StepWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid;
  border-color: ${(props)=>props.theme.colors["border-primary"]};
  border-radius: ${(props)=>props.theme.radius["radius-full"]};
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  justify-content:center;
  width: fit-content;
  padding: ${(props)=>props.theme.spacing["spacing-01"]} ${(props)=>props.theme.spacing["spacing-05"]};
  color: ${(props)=>props.theme.colors["text-secondary-inverse"]};
  ${(props)=>props.theme.typography["pre-caption-03"]}
`

const TextWrapper = styled.div`
  color: ${(props)=>props.theme.colors["text-primary-inverse"]};
  ${(props)=>props.theme.typography["pre-body-05"]}
`


const TooltipContainer = styled.div`
  width: 280px;
  display:flex;
  height:fit-content;
  align-items:flex-start;
  align-self: stretch;
  flex: 0 0 auto;
  flex-direction:column;
  background-color: ${(props)=>props.theme.colors["background-inverse"]};  
  border-radius: ${(props)=>props.theme.radius["radius-03"]};
  padding: ${(props)=>props.theme.spacing["spacing-06"]};
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  user-select: none;
  z-index:9002;
  cursor: pointer;
`