import React, { useState } from "react";
import styled from "styled-components";
import { InfoTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";


const StyledInfoPanel = styled.div`
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  padding: var(--tokens-spacing-07-duplicate) var(--tokens-spacing-11-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-07-duplicate);
  position: relative;
  box-sizing:border-box;
  width: 336px;
  height: 300px;
  height: 100%;
`;

export const InfoPanel = (): JSX.Element => {
  
  const [selectNum, setSelectNum] = useState(0);

  const handleSelect = (num: number) => {
    setSelectNum(num);
  }

  return (
    <StyledInfoPanel className={"tutorials tutorial-step-5 tutorial-focus-5"}>
      <TabHeader selectNum={selectNum} onClick={handleSelect}/>
      {selectNum===0 &&<InfoTabContainer />}
    </StyledInfoPanel>
  );
};
