import { createContext } from "react"

const TextPanelContextDefault = {
  textList:[],
  shadowList:[],

  setTextList:(newList)=>{},
  setShadowList:(newList)=>{},
}

export const TextPanelContext = createContext(TextPanelContextDefault);