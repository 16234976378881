import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Slider } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import { InputStepper } from "../../../../../../../Components/pages/Editor/InputStepper";
import { MusicListItem } from "../../../../../../../Components/pages/Editor/MusicListItem";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../../../../editorPageContext";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";
import { useTranslation } from "react-i18next";

import AlertModal from "../../../../../../../Components/common/AlertModal";
import { ReactComponent as AlertIcon } from "../../../../../../../Assets/ModalIllu/check.svg";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));


export const MusicTabContent = (props): JSX.Element => {
  const { t } = useTranslation();
  const [volume, setVolume] = useState(100);
  const [fadeIn, setFadeIn ] = useState(0);
  const [fadeOut, setFadeOut] = useState(0);

  const [audioState, setAudioState] = useState("normal")

  const [url, setUrl] = useState(undefined);
  const [title, setTitle] = useState("");
  const [duration, setDuration] = useState("00:00");

  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext);


  useEffect(()=>{
    setVolume(editorContext.bgmVolume);
  },[])

  const handleChangeVolume = (event: Event, newValue: number | number[]) => {
    setVolume(newValue as number);
    editorContext.setBgmVolume(newValue as number);
  };

  const handleChangeFadeIn = (event: Event, newValue: number | number[]) => {
    setFadeIn(newValue as number);
  };

  const handleChangeFadeOut = (event: Event, newValue: number | number[]) => {
    setFadeOut(newValue as number);
  };

  const handleChangeCharacter = (newCharacter: string) => {
    
  };

  const handleCommand = (command: string) => {
    //(command);
    switch(audioState){
      case "normal":
        setAudioState("play");
        break;
      case "play":
        setAudioState("normal");
        break;
    }
  }

  const hideItem = true;

  useEffect(()=>{
    const index = scenarioContext.selectedBgmIndex;
    if(index !==undefined){
      if(scenarioContext.BGMInfo !== undefined && editorPageContext.bgmUrlList !== undefined){
        const duration = scenarioContext.BGMInfo[index].duration || 0; 
        const durationString = "00:"+("00"+duration.toString()).slice(-2)
        
        setUrl(editorPageContext.bgmUrlList[index]||"");
        setTitle(scenarioContext.BGMInfo[index].musicTitle||"")
        setDuration(durationString)
        setAudioState("normal");
      }
    }
  },[scenarioContext.selectedBgmIndex])

  return (
    <StyledFrame>
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption"  sx={{ flex: 1, color: 'grey.700' }}>BGM</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{paddingLeft:0, paddingRight:0 }} >
          <Box display="flex" flexDirection="column" gap={1}>
            <Box display="flex" alignItems="center" justifyContent="space-between" gap={2} width="100%">
              <MusicListItem url={url} title={title} artist="" time={duration} state={audioState} volume={volume} onCommand={handleCommand} addBtnHide/>
            </Box>
                  
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}} width="100%">
              <Typography variant="body2" color="secondary" sx={{ width: 64 }}>{t("pages-editor-main-musicpanel.c1")}</Typography>
              <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={100} value={volume} min={0} max={100} onChange={handleChangeVolume}/>
              <InputStepper unitText="%" value={volume} width={104} min={0} max={100} onChange={(val:number)=>{setVolume(val)}} />
            </Box>
            {
              !hideItem &&
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}} width="100%">
                  <Typography variant="body2" color="secondary" sx={{ width: 64 }}>{t("pages-editor-main-musicpanel.c2")}</Typography>
                  <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={fadeIn} min={0} max={15} onChange={handleChangeFadeIn}/>
                  <InputStepper unitText="s" value={fadeIn} width={104} min={0} max={15} onChange={(val:number)=>{setFadeIn(val)}} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2}} width="100%">
                  <Typography variant="body2" color="secondary" sx={{ width: 64 }}>{t("pages-editor-main-musicpanel.c3")}</Typography>
                  <Slider sx={{ flex: 1 }} color="primary" size="small" defaultValue={0} value={fadeOut} min={0} max={15} onChange={handleChangeFadeOut}/>
                  <InputStepper unitText="s" value={fadeOut} width={104} min={0} max={15} onChange={(val:number)=>{setFadeOut(val)}} />
                </Box>
              </>
            }
           </Box>
        </AccordionDetails>
      </Accordion >
      
    </StyledFrame>
  );
};